
import { computed, defineComponent, reactive, ref, watch } from 'vue'
import axios from 'axios'
import { useStore } from '../../store'
import { useRoute, useRouter } from 'vue-router'
import { useMeta } from 'vue-meta'
import BreadCrumb from '../../components/BreadCrumb.vue'
import VPagination from '@hennge/vue3-pagination'
import '@hennge/vue3-pagination/dist/vue3-pagination.css'
import getDeviceToken from '@/functions/getDeviceToken'

export default defineComponent({
	components: {
		BreadCrumb,
		VPagination,
	},
	setup() {
		// store
		const store = useStore()

		// route
		const route = useRoute()

		// router
		const router = useRouter()

		// state
		const state = reactive({
			affiliateCategoryTags: [],
			affiliates: [],
			affiliateCategoryTitle: '',
			affiliateCategoryTagTitle: '',
			affiliateCategoryDescription: '',
			affiliatesRecommended: [],
			affiliateWord: route.query.word ?? '',
			affiliateItemCategoryId: route.query.affiliate_item_category_id ?? '',
			filteredAffiliateCategoryTags: [],
			isVisibleSearch: false,
			pageNumber: Number(route.params.page),
		})

		if (state.affiliateWord && state.affiliateWord !== '') {
			state.isVisibleSearch = true
		}

		const slicedAffiliateItemsRecommended = computed(() => {
			// if (state.filteredAffiliateCategoryTags.length !== 0) {
			// 	const tmp: any = []
			// 	let item: any
			// 	for (item of state.affiliatesRecommended) {
			// 		for (let tagNum of state.filteredAffiliateCategoryTags) {
			// 			if (item.affiliate_category_tag_ids.includes(tagNum)) {
			// 				tmp.push(item)
			// 			}
			// 		}
			// 	}
			// 	return tmp
			// }
			return state.affiliatesRecommended
		})

		const slicedAffiliateItems = computed(() => {
			// if (state.filteredAffiliateCategoryTags.length !== 0) {
			// 	const tmp: any = []
			// 	let item: any
			// 	for (item of state.affiliates) {
			// 		for (let tagNum of state.filteredAffiliateCategoryTags) {
			// 			if (item.affiliate_category_tag_ids.includes(tagNum)) {
			// 				tmp.push(item)
			// 			}
			// 		}
			// 	}
			// 	return tmp.slice((state.pageNumber - 1) * 10, (state.pageNumber - 1) * 10 + 10)
			// }
			return state.affiliates.slice((state.pageNumber - 1) * 10, (state.pageNumber - 1) * 10 + 10)
		})

		const paging = () => {
			return router.push({ name: 'AffiliateItemIndex', params: { page: state.pageNumber }, query: router.currentRoute.value.query })
		}

		// const testNumber = 1

		// const filterAffiliateItem = computed(() => {
		// 	const filteredCategory = state.affiliates.filter((item: any) => {
		// 		return item.affiliate_item_category_id === testNumber
		// 	})
		// 	return filteredCategory
		// })

		// const filterAffiliateItem = () => {
		// 	const filteredCategory = state.affiliates.filter((item: any) => {
		// 		return item.affiliate_item_category_id === testNumber
		// 	})

		// 	const filteredTag = filteredCategory.filter((item: any) => {
		// 		console.log(item)

		// 		return item.affiliate_category_tag_ids.includes(1)
		// 	})
		// 	console.log(filteredTag)
		// }

		// const affiliateItemCategoryId = ref('1')
		// if (router.currentRoute.value.query.affiliate_item_category_id === '1') {
		// 	affiliateItemCategoryId.value = router.currentRoute.value.query.signup_done as string
		// }

		const loading = ref(false)

		const getAffiliateItem = async () => {
			try {
				axios.defaults.headers.common['Authorization'] = 'Bearer ' + store.state.deviceToken
				if (state.affiliateItemCategoryId && state.affiliateItemCategoryId !== '') {
					loading.value = true
					const res = await axios.get('/api2/affiliate_item/index', {
						params: {
							affiliate_item_category_id: state.affiliateItemCategoryId as string,
							word: state.affiliateWord,
						},
					})

					state.affiliateCategoryTags = res.data.affiliate_category_tags
					state.affiliates = res.data.affiliates.filter((item: any) => item.is_recommended === false)
					state.affiliatesRecommended = res.data.affiliates.filter((item: any) => item.is_recommended === true)

					loading.value = false
				} else {
					loading.value = true
					const res = await axios.get('/api2/affiliate_item/index', {
						params: {
							affiliate_item_category_id: state.affiliateItemCategoryId as string,
							word: state.affiliateWord,
							affiliate_category_tag_ids: route.query.affiliate_category_tag_ids,
						},
					})

					state.affiliateCategoryTags = res.data.affiliate_category_tags
					state.affiliates = res.data.affiliates

					loading.value = false

					if (route.query.affiliate_category_tag_ids !== '') {
						state.affiliateCategoryTagTitle = res.data.affiliate_category_tags.filter((tag: any) => {
							return tag.id === Number(route.query.affiliate_category_tag_ids)
						})[0].name
					}
				}

				if (state.affiliateItemCategoryId === '1') {
					state.affiliateCategoryTitle = 'クレジットカード'
					state.affiliateCategoryDescription = 'クレジットカード新規発行でもらえるお得なポイントランキング<br>ポモチポイント + カード会社のキャンペーンポイント'
				} else if (state.affiliateItemCategoryId === '2') {
					state.affiliateCategoryTitle = 'ショッピング'
					state.affiliateCategoryDescription = 'ショッピングでポイントが貯まる！ポモチを経由して購入することでポイントが還元されます。'
				} else if (state.affiliateItemCategoryId === '3') {
					state.affiliateCategoryTitle = '広告'
					state.affiliateCategoryDescription = 'ポモチポイントがもらえるおすすめの広告一覧'
				}
			} catch (error) {
				if (axios.isAxiosError(error)) {
					if (error.response) {
						alert(error.response.data.message)
					}
				}
			}
		}

		// ワードで案件取得
		// const getWordAffiliateItem = async () => {
		// 	try {
		// 		axios.defaults.headers.common['Authorization'] = 'Bearer ' + store.state.deviceToken
		// 		const res = await axios.get('/api2/affiliate_item/index', {
		// 			params: {
		// 				word: state.affiliateWord,
		// 				affiliate_item_category_id: route.query.affiliate_item_category_id,
		// 			},
		// 		})

		// 		state.affiliateCategoryTags = res.data.affiliate_category_tags
		// 		state.affiliates = res.data.affiliates

		// 		if (router.currentRoute.value.query.affiliate_item_category_id === '1') {
		// 			state.affiliateCategoryTitle = 'クレジットカード'
		// 			state.affiliateCategoryDescription = 'クレジットカード新規発行でもらえるお得なポイントランキング<br>ポモチポイント + カード会社のキャンペーンポイント'
		// 		} else if (router.currentRoute.value.query.affiliate_item_category_id === '2') {
		// 			state.affiliateCategoryTitle = 'ショッピング'
		// 			state.affiliateCategoryDescription = 'ショッピングでポイントが貯まる！ポモチを経由して購入することでポイントが還元されます。'
		// 		} else if (router.currentRoute.value.query.affiliate_item_category_id === '3') {
		// 			state.affiliateCategoryTitle = '広告'
		// 			state.affiliateCategoryDescription = 'ポモチポイントがもらえるおすすめの広告一覧'
		// 		}
		// 	} catch (error: any) {
		// 		console.log(error)
		// 	}
		// }

		const getAffiliateItemFilteredByTags = async () => {
			try {
				axios.defaults.headers.common['Authorization'] = 'Bearer ' + store.state.deviceToken
				if (state.affiliateItemCategoryId && state.affiliateItemCategoryId !== '') {
					const res = await axios.get('/api2/affiliate_item/index', {
						params: {
							affiliate_item_category_id: state.affiliateItemCategoryId as string,
							word: state.affiliateWord,
							affiliate_category_tag_ids: state.filteredAffiliateCategoryTags,
						},
					})

					state.affiliateCategoryTags = res.data.affiliate_category_tags
					state.affiliates = res.data.affiliates.filter((item: any) => item.is_recommended === false)
					state.affiliatesRecommended = res.data.affiliates.filter((item: any) => item.is_recommended === true)
				} else {
					const res = await axios.get('/api2/affiliate_item/index', {
						params: {
							affiliate_item_category_id: state.affiliateItemCategoryId as string,
							word: state.affiliateWord,
							affiliate_category_tag_ids: state.filteredAffiliateCategoryTags,
						},
					})

					state.affiliateCategoryTags = res.data.affiliate_category_tags
					state.affiliates = res.data.affiliates
				}

				if (state.affiliateItemCategoryId === '1') {
					state.affiliateCategoryTitle = 'クレジットカード'
					state.affiliateCategoryDescription = 'クレジットカード新規発行でもらえるお得なポイントランキング<br>ポモチポイント + カード会社のキャンペーンポイント'
				} else if (state.affiliateItemCategoryId === '2') {
					state.affiliateCategoryTitle = 'ショッピング'
					state.affiliateCategoryDescription = 'ショッピングでポイントが貯まる！ポモチを経由して購入することでポイントが還元されます。'
				} else if (state.affiliateItemCategoryId === '3') {
					state.affiliateCategoryTitle = '広告'
					state.affiliateCategoryDescription = 'ポモチポイントがもらえるおすすめの広告一覧'
				}
			} catch (error) {
				if (axios.isAxiosError(error)) {
					if (error.response) {
						alert(error.response.data.message)
					}
				}
			}
		}

		const searchByWord = () => {
			return router.push({ name: 'AffiliateItemIndex', query: { word: [1, 2] } })
		}

		// デバイストークン取得後にAPI起動
		const { deviceToken } = getDeviceToken()
		const start = async () => {
			await deviceToken()
			await getAffiliateItem()
		}
		start()

		// メタ情報
		const { meta } = useMeta({
			title: '',
			description: '',
			bodyAttrs: {
				class: '',
			},
		})

		watch(
			() => state.filteredAffiliateCategoryTags,
			() => {
				getAffiliateItemFilteredByTags()
				state.pageNumber = 1
				route.params.page = '1'
			},
		)

		return {
			state,
			route,
			router,
			slicedAffiliateItemsRecommended,
			slicedAffiliateItems,
			searchByWord,
			paging,
			loading,
			getAffiliateItemFilteredByTags,
		}
	},
})
