
import { defineComponent, reactive, ref, watchEffect } from 'vue'
import { useRoute, useRouter } from 'vue-router'
import axios from 'axios'
import { useMeta } from 'vue-meta'
import BreadCrumb from '../../components/BreadCrumb.vue'
import { useStore } from '@/store'
import getDeviceToken from '@/functions/getDeviceToken'

// 型定義
interface ContributionInfo {
	code: string
	have_number?: boolean
	name: string
	no_number: number
	yes_number: number
	no_percentage?: number
	yes_percentage?: number
}
interface Contribution {
	[key: string]: ContributionInfo
}

export default defineComponent({
	components: {
		BreadCrumb,
	},
	setup() {
		const { meta } = useMeta({
			title: '（店舗名）の口コミ一覧',
			description: '（店舗名）の口コミ投票を確認。（店舗住所）の（店舗名）で支払い可能な決済サービスをチェック。',
		})

		// route
		const route = useRoute()

		// router
		const router = useRouter()

		// store
		const store = useStore()

		// デバイストークン取得後にAPI起動
		const { deviceToken } = getDeviceToken()
		const start = async () => {
			await deviceToken()
			await getContribution()
			await getBranch()
		}
		start()
		// テスト完了までトップに戻す
		// router.push({ name: 'Top' })

		// データを入れるための定数
		const creditCards: Contribution = reactive({})
		const codePays: Contribution = reactive({})
		const noTouchPays: Contribution = reactive({})
		const otherPayments: Contribution = reactive({})

		const added = ref('none')

		if (router.currentRoute.value.query.add !== '') {
			added.value = router.currentRoute.value.query.add as string
		}

		// const codePay: {
		// 	line_pay: Array<string | number>
		// 	paypay: Array<string | number>
		// 	rakuten_pay: Array<string | number>
		// 	d_pay: Array<string | number>
		// 	au_pay: Array<string | number>
		// 	merpay_code: Array<string | number>
		// 	quo_card_pay: Array<string | number>
		// 	fami_pay: Array<string | number>
		// 	alipay: Array<string | number>
		// 	we_chat_pay: Array<string | number>
		// 	amazon_pay: Array<string | number>
		// 	ginko_pay: Array<string | number>
		// 	bank_pay: Array<string | number>
		// 	j_coin_pay: Array<string | number>
		// 	pring: Array<string | number>
		// } = reactive({
		// 	line_pay: [],
		// 	paypay: [],
		// 	rakuten_pay: [],
		// 	d_pay: [],
		// 	au_pay: [],
		// 	merpay_code: [],
		// 	quo_card_pay: [],
		// 	fami_pay: [],
		// 	alipay: [],
		// 	we_chat_pay: [],
		// 	amazon_pay: [],
		// 	ginko_pay: [],
		// 	bank_pay: [],
		// 	j_coin_pay: [],
		// 	pring: [],
		// })

		// const noTouchPay: {
		// 	quic_pay: Array<string | number>
		// 	quic_pay_plus: Array<string | number>
		// 	docomo_id: Array<string | number>
		// 	waon: Array<string | number>
		// 	nanaco: Array<string | number>
		// 	edy: Array<string | number>
		// 	transportation_card: Array<string | number>
		// 	pitapa: Array<string | number>
		// } = reactive({
		// 	quic_pay: [],
		// 	quic_pay_plus: [],
		// 	docomo_id: [],
		// 	waon: [],
		// 	nanaco: [],
		// 	edy: [],
		// 	transportation_card: [],
		// 	pitapa: [],
		// })

		// const otherPayment: {
		// 	jf_card: Array<string | number>
		// 	quo_card: Array<string | number>
		// 	okomeken: Array<string | number>
		// 	zenkokuhyakkatennkyoutsusyouhinken: Array<string | number>
		// } = reactive({
		// 	jf_card: [],
		// 	quo_card: [],
		// 	okomeken: [],
		// 	zenkokuhyakkatennkyoutsusyouhinken: [],
		// })

		// 口コミ取得
		const getContribution = async () => {
			try {
				axios.defaults.headers.common['Authorization'] = 'Bearer ' + store.state.deviceToken
				const res = await axios.get(`/api2/contribution/answers/${route.params.id}`)
				let creditCard: ContributionInfo
				for (creditCard of res.data[2].items) {
					const code = creditCard.code
					creditCards[code] = creditCard

					// 口コミ数が0だったらhave_numberをfalseに
					if (creditCard.yes_number === 0 && creditCard.no_number === 0) {
						creditCard.have_number = false
					} else {
						// 口コミがあれば割合を計算
						creditCard.have_number = true
						creditCard.yes_percentage = Math.round((creditCard.yes_number / (creditCard.yes_number + creditCard.no_number)) * 100)
						creditCard.no_percentage = Math.round((creditCard.no_number / (creditCard.yes_number + creditCard.no_number)) * 100)
					}
				}

				let codePay: ContributionInfo
				for (codePay of res.data[0].items) {
					const code = codePay.code
					codePays[code] = codePay
					// 口コミ数が0だったらhave_numberをfalseに
					if (codePay.yes_number === 0 && codePay.no_number === 0) {
						codePay.have_number = false
					} else {
						// 口コミがあれば割合を計算
						codePay.have_number = true
						codePay.yes_percentage = Math.round((codePay.yes_number / (codePay.yes_number + codePay.no_number)) * 100)
						codePay.no_percentage = Math.round((codePay.no_number / (codePay.yes_number + codePay.no_number)) * 100)
					}
				}

				let noTouchPay: ContributionInfo
				for (noTouchPay of res.data[1].items) {
					const code = noTouchPay.code
					noTouchPays[code] = noTouchPay
					// 口コミ数が0だったらhave_numberをfalseに
					if (noTouchPay.yes_number === 0 && noTouchPay.no_number === 0) {
						noTouchPay.have_number = false
					} else {
						// 口コミがあれば割合を計算
						noTouchPay.have_number = true
						noTouchPay.yes_percentage = Math.round((noTouchPay.yes_number / (noTouchPay.yes_number + noTouchPay.no_number)) * 100)
						noTouchPay.no_percentage = Math.round((noTouchPay.no_number / (noTouchPay.yes_number + noTouchPay.no_number)) * 100)
					}
				}

				let otherPayment: ContributionInfo
				for (otherPayment of res.data[3].items) {
					const code = otherPayment.code
					otherPayments[code] = otherPayment
					// 口コミ数が0だったらhave_numberをfalseに
					if (otherPayment.yes_number === 0 && otherPayment.no_number === 0) {
						otherPayment.have_number = false
					} else {
						// 口コミがあれば割合を計算
						otherPayment.have_number = true
						otherPayment.yes_percentage = Math.round((otherPayment.yes_number / (otherPayment.yes_number + otherPayment.no_number)) * 100)
						otherPayment.no_percentage = Math.round((otherPayment.no_number / (otherPayment.yes_number + otherPayment.no_number)) * 100)
					}
				}

				// for (let crItem in creditCards) {
				// 	creditCards[crItem] = res.data[2].items.find((value:any) => value.code === crItem)
				// 	console.log(creditCards[crItem]);

				// 	if (creditCards[crItem].yes_number === 0 && creditCards[crItem].no_number === 0) {
				// 		creditCards[crItem].donot_have_number = true
				// 	} else {
				// 		creditCards[crItem].yes_percentage = Math.round(
				// 			(creditCards[crItem].yes_number /
				// 				(creditCards[crItem].yes_number + creditCards[crItem].no_number)) *
				// 				100,
				// 		)
				// 		creditCards[crItem].no_percentage = Math.round(
				// 			(creditCards[crItem].no_number /
				// 				(creditCards[crItem].yes_number + creditCards[crItem].no_number)) *
				// 				100,
				// 		)
				// 	}
				// }
				// for (let codeItem in codePays) {
				// 	codePay[codeItem] = res.data[0].items.find((value) => value.code === codeItem)
				// 	if (codePay[codeItem].yes_number === 0 && codePay[codeItem].no_number === 0) {
				// 		codePay[codeItem].donot_have_number = true
				// 	} else {
				// 		codePay[codeItem].yes_percentage = Math.round(
				// 			(codePay[codeItem].yes_number /
				// 				(codePay[codeItem].yes_number + codePay[codeItem].no_number)) *
				// 				100,
				// 		)
				// 		codePay[codeItem].no_percentage = Math.round(
				// 			(codePay[codeItem].no_number /
				// 				(codePay[codeItem].yes_number + codePay[codeItem].no_number)) *
				// 				100,
				// 		)
				// 	}
				// }
				// for (let ntItem in noTouchPay) {
				// 	noTouchPay[ntItem] = res.data[1].items.find((value) => value.code === ntItem)

				// 	if (noTouchPay[ntItem].yes_number === 0 && noTouchPay[ntItem].no_number === 0) {
				// 		noTouchPay[ntItem].donot_have_number = true
				// 	} else {
				// 		noTouchPay[ntItem].yes_percentage = Math.round(
				// 			(noTouchPay[ntItem].yes_number /
				// 				(noTouchPay[ntItem].yes_number + noTouchPay[ntItem].no_number)) *
				// 				100,
				// 		)
				// 		noTouchPay[ntItem].no_percentage = Math.round(
				// 			(noTouchPay[ntItem].no_number /
				// 				(noTouchPay[ntItem].yes_number + noTouchPay[ntItem].no_number)) *
				// 				100,
				// 		)
				// 	}
				// }
				// for (let otItem in otherPayment) {
				// 	otherPayment[otItem] = res.data[3].items.find((value) => value.code === otItem)

				// 	if (otherPayment[otItem].yes_number === 0 && otherPayment[otItem].no_number === 0) {
				// 		otherPayment[otItem].donot_have_number = true
				// 	} else {
				// 		otherPayment[otItem].yes_percentage = Math.round(
				// 			(otherPayment[otItem].yes_number /
				// 				(otherPayment[otItem].yes_number + otherPayment[otItem].no_number)) *
				// 				100,
				// 		)
				// 		otherPayment[otItem].no_percentage = Math.round(
				// 			(otherPayment[otItem].no_number /
				// 				(otherPayment[otItem].yes_number + otherPayment[otItem].no_number)) *
				// 				100,
				// 		)
				// 	}
				// }
			} catch (error: any) {
				alert(error.response.data.message)
			}
		}

		const branch = reactive({
			name: '',
			address: '',
		})
		const getBranch = async () => {
			axios.defaults.headers.common['Authorization'] = 'Bearer ' + store.state.deviceToken
			const res = await axios.get(`/api2/branch/detail/${route.params.id}?price=1000`)
			branch.name = res.data.name
			branch.address = res.data.address
		}

		watchEffect(() => {
			meta.title = `${branch.name}の口コミ一覧`
			meta.description = `${branch.name}の口コミ投票を確認。${branch.address}の${branch.name}で支払い可能な決済サービスをチェック。`
		})

		return {
			router,
			added,
			creditCards,
			codePays,
			noTouchPays,
			otherPayments,
		}
	},
})
