
import { defineComponent } from 'vue'
import { useMeta } from 'vue-meta'
import BreadCrumb from '../../components/BreadCrumb.vue'

export default defineComponent({
	components: {
		BreadCrumb,
	},
	setup() {
		useMeta({
			title: '友だち紹介ポイント制度の仕組み',
			description: 'ポモチでは友だちがポイントを獲得するとあなたにもポイントが入ります。友だちを招待してからポイント獲得までの流れを確認しましょう。',
		})
	},
})
