import { InjectionKey } from 'vue'
import { createStore, useStore as baseUseStore, Store } from 'vuex'
import createPersistedState from 'vuex-persistedstate'
import * as Cookies from 'js-cookie'

export interface State {
	isLoggedIn: boolean
	fbLoggedIn: boolean
	deviceToken: string
	fbToken: string
	ignitionGetDeviceTokenTime: any
}

export const key: InjectionKey<Store<State>> = Symbol()

export const store = createStore<State>({
	state: {
		isLoggedIn: false,
		fbLoggedIn: false,
		deviceToken: '',
		fbToken: '',
		ignitionGetDeviceTokenTime: '',
	},
	mutations: {
		setUser(state) {
			state.isLoggedIn = true
		},
		unsetUser(state) {
			state.isLoggedIn = false
		},
		setFBUser(state) {
			state.fbLoggedIn = true
		},
		unsetFBUser(state) {
			state.fbLoggedIn = false
		},
		setDeviceToken(state, device) {
			state.deviceToken = device.deviceToken
		},
		emptyDeviceToken(state) {
			state.deviceToken = ''
		},
		setFBToken(state, token) {
			state.fbToken = token.data
		},
		emptyFBToken(state) {
			state.fbToken = ''
		},
		setIgnitionGetDeviceTokenTime(state) {
			state.ignitionGetDeviceTokenTime = new Date().getTime() / (1000 * 60)
		},
	},
	actions: {
		getLoginUser(context) {
			context.commit('setUser')
		},
		changeLoginUserState(context) {
			context.commit('unsetUser')
		},
		getLoginFBUser(context) {
			context.commit('setFBUser')
		},
		changeLoginFBUserState(context) {
			context.commit('unsetFBUser')
		},
		getDeviceToken(context, device) {
			context.commit('setDeviceToken', device)
		},
		deleteDeviceToken(context) {
			context.commit('emptyDeviceToken')
		},
		getFBToken(context, token) {
			context.commit('setFBToken', token)
		},
		deleteFBToken(context) {
			context.commit('emptyFBToken')
		},
		getIgnitionGetDeviceTokenTime(context) {
			context.commit('setIgnitionGetDeviceTokenTime')
		},
	},
	plugins: [
		createPersistedState({
			key: 'your app key',
			storage: {
				getItem: (key) => Cookies.get(key),
				setItem: (key, value) => Cookies.set(key, value, { expires: 365, secure: true }),
				removeItem: (key) => Cookies.remove(key),
			},
		}),
	],
})

// 独自のuserStoreメソッドを定義する
export const useStore = () => {
	// InjectionKeyをuserStoreメソッドに渡す
	return baseUseStore(key)
}
