
import { computed, defineComponent, reactive, ref } from 'vue'
import axios, { AxiosResponse } from 'axios'
import { useStore } from '../../store'
import { useRouter } from 'vue-router'
import { useMeta } from 'vue-meta'
import BreadCrumb from '../../components/BreadCrumb.vue'
import UsersSidebar from '../../components/UsersSidebar.vue'
import getDeviceToken from '@/functions/getDeviceToken'

type ResData = {
	gifts: Gift[]
}

type State = {
	gifts?: Gift[]
	error_message?: string
}

type Gift = {
	description: string
	description_detail: string
	exchange_timing: string
	id: number
	image_url: string
	name: string
	prices: Price[]
	type: number
	withdrawal_product_id: number | null
	withdrawal_product_type: string | null
}

type Price = {
	can_exchange: boolean
	description: string
	exchange_title: string
	point: number
	price: number
	rate: number
	unit: string
}

export default defineComponent({
	components: {
		BreadCrumb,
		UsersSidebar,
	},
	setup() {
		useMeta({
			title: 'ポイント交換',
			description: 'ポイント交換',
		})

		// store
		const store = useStore()

		// router
		const router = useRouter()

		// ログインしていないならトップに遷移
		if (store.state.isLoggedIn === false) {
			router.push({ name: 'Top' })
		}

		// デバイストークン取得後にAPI起動
		const { deviceToken } = getDeviceToken()
		const start = async () => {
			await deviceToken()
			await getProfile()
		}
		start()

		// state
		const state = reactive<State>({
			gifts: undefined,
			error_message: '',
		})

		const character_url = ref('')

		// プロフィール取得
		const getProfile = async () => {
			try {
				axios.defaults.headers.common['Authorization'] = 'Bearer ' + store.state.deviceToken
				const res = await axios.get('/api2/user/profile')

				character_url.value = res.data.user.character.image_url
			} catch (error) {
				if (axios.isAxiosError(error)) {
					alert(error.response?.data.message)
				}
			}
		}

		const getGifts = async () => {
			try {
				axios.defaults.headers.common['Authorization'] = 'Bearer ' + store.state.deviceToken
				const res: AxiosResponse<ResData> = await axios.get('/api2/gift/index')
				// console.log(res.data.gifts.filter((gift) => gift.id === 1))

				// res.data.gifts.forEach((gift) => {
				// 	console.log(gift)

				// 	if (gift.id === 1) {
				// 		state.gift = gift
				// 	}
				// })
				state.gifts = res.data.gifts
			} catch (error) {
				if (axios.isAxiosError(error)) {
					alert(error.response?.data.message)
				}
			}
		}
		getGifts()

		// （テスト用）特定のユーザーじゃなければトップへリダイレクト
		/*
		const getProfileAndBackHome = async () => {
			try {
				axios.defaults.headers.common['Authorization'] = 'Bearer ' + store.state.deviceToken
				const res = await axios.get('/api2/user/profile')
				if (res.data.user.id !== 75944 && res.data.user.id !== 979 && res.data.user.id !== 16216) {
					return router.push({ name: 'Top' })
				}
			} catch (error: any) {
				alert(error.response.data.message)
			}
		}
		// getProfileAndBackHome()
		*/

		return { state, character_url }
	},
})
