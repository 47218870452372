
import { computed, defineComponent, reactive, ref } from 'vue'
import axios from 'axios'
import { useStore } from '../../store'
import { useRouter } from 'vue-router'
import { useMeta } from 'vue-meta'
import BreadCrumb from '../../components/BreadCrumb.vue'
import UsersSidebar from '../../components/UsersSidebar.vue'
import getDeviceToken from '@/functions/getDeviceToken'

type InvitationInfo = {
	invitation_code: string
	share_text: string
	twitter_share_url: string
	line_share_url: string
	short_description: string
	benefits_image_url: string
	precaution: string
	steps_image_url: string
}

export default defineComponent({
	components: {
		BreadCrumb,
		UsersSidebar,
	},
	setup() {
		useMeta({
			title: '友だちを紹介する',
			description: '友だちを紹介する',
		})

		// store
		const store = useStore()

		// router
		const router = useRouter()

		// state
		const state = ref<InvitationInfo | null>(null)

		// ログインしていないならトップに遷移
		if (store.state.isLoggedIn === false) {
			router.push({ name: 'Top' })
		}

		// デバイストークン取得後にAPI起動
		const { deviceToken } = getDeviceToken()
		const start = async () => {
			await deviceToken()
			await getProfile()
			await getInvitationInfo()
		}
		start()

		const character_url = ref('')

		// （テスト用）特定のユーザーじゃなければトップへリダイレクト
		const getProfileAndBackHome = async () => {
			try {
				axios.defaults.headers.common['Authorization'] = 'Bearer ' + store.state.deviceToken
				const res = await axios.get('/api2/user/profile')
				if (res.data.user.id !== 75944 && res.data.user.id !== 979 && res.data.user.id !== 16216) {
					return router.push({ name: 'Top' })
				}
			} catch (error: any) {
				alert(error.response.data.message)
			}
		}
		// getProfileAndBackHome()

		// プロフィール取得
		const getProfile = async () => {
			try {
				axios.defaults.headers.common['Authorization'] = 'Bearer ' + store.state.deviceToken
				const res = await axios.get('/api2/user/profile')

				character_url.value = res.data.user.character.image_url
			} catch (error) {
				if (axios.isAxiosError(error)) {
					alert(error.response?.data.message)
				}
			}
		}

		const getInvitationInfo = async () => {
			try {
				axios.defaults.headers.common['Authorization'] = 'Bearer ' + store.state.deviceToken
				const res = await axios.get('/api2/user/invitation_info')
				state.value = res.data
			} catch (error) {
				if (axios.isAxiosError(error)) {
					alert(error.response?.data.message)
				}
			}
		}

		const copyToClipboard = async (e: any) => {
			try {
				await navigator.clipboard.writeText(e.target.previousElementSibling.innerText)
				return alert('紹介コードをコピーしました。')
			} catch (error) {
				return alert('コピーに失敗しました')
			}
		}

		return {
			state,
			character_url,
			copyToClipboard,
		}
	},
})
