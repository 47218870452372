
import { computed, defineComponent, reactive } from 'vue'
import axios from 'axios'
import { useMeta } from 'vue-meta'
import { useStore } from '../../store'
import { useRouter } from 'vue-router'

import UsersSidebar from '../../components/UsersSidebar.vue'
import BreadCrumb from '../../components/BreadCrumb.vue'
import getDeviceToken from '@/functions/getDeviceToken'

interface CustomURLSearchParams extends URLSearchParams {
	append(name: 'values', value: string | string[] | undefined | Blob, fileName?: string): void
}

export default defineComponent({
	components: {
		UsersSidebar,
		BreadCrumb,
	},
	setup() {
		useMeta({
			title: '所持しているクレカの情報',
			description: '自分の持っているクレジットカードを入力すると店舗詳細画面で自分の持っているカードで一番還元率の高い支払い方法がわかります。',
		})

		// vuex
		const store = useStore()

		// router
		const router = useRouter()

		// ログインしていないならトップに遷移
		if (store.state.isLoggedIn === false) {
			router.push({ name: 'Top' })
		}

		// デバイストークン取得後にAPI起動
		const { deviceToken } = getDeviceToken()
		const start = async () => {
			await deviceToken()
			await getCards()
		}
		start()

		// カード情報格納用オブジェクト
		const cards: {
			haved: boolean
			keyword?: string
			info: Array<string | number>
			type: Array<string>
		} = reactive({
			haved: false,
			keyword: '',
			info: [],
			type: [],
		})

		// 所持カード情報取得
		const getCards = async () => {
			try {
				axios.defaults.headers.common['Authorization'] = 'Bearer ' + store.state.deviceToken
				const res = await axios.get('/api2/user_information/get_cards')
				cards.info = res.data.options
				cards.type = res.data.values
			} catch (error: any) {
				alert(error.response.data.message)
			}
		}

		// 利用しているクレジットカード更新判定用
		const updateDone = reactive({
			tof: false,
		})

		// 所持カード情報更新
		const updateCards = async () => {
			try {
				// 更新判定をtrueに
				updateDone.tof = true

				const data = new URLSearchParams() as CustomURLSearchParams
				data.append('values', cards.type)
				axios.defaults.headers.common['Authorization'] = 'Bearer ' + store.state.deviceToken
				const res = await axios.post('/api2/user_information/update_cards', data)

				// 更新後最上部までスクロール
				window.scrollTo(0, 0)
			} catch (error: any) {
				alert(error.response.data.message)
			}
		}

		// 所持しているカードと検索機能でフィルターをかける
		const filterCards = computed(() => {
			if (cards.haved) {
				const filteredHaved = cards.info.filter((card: any) => {
					return cards.type.includes(card.value)
				})
				if (cards.keyword) {
					const filteredHavedKeyword = filteredHaved.filter((card: any) => {
						if (typeof cards.keyword === 'string') {
							return card.title.toLowerCase().includes(cards.keyword.toLowerCase())
						}
					})
					return filteredHavedKeyword
				}
				return filteredHaved
			} else {
				if (cards.keyword) {
					const filteredKeyword = cards.info.filter((card: any) => {
						if (typeof cards.keyword === 'string') {
							return card.title.toLowerCase().includes(cards.keyword.toLowerCase())
						}
					})
					return filteredKeyword
				}
			}
			return cards.info
		})

		return {
			cards,
			updateCards,
			updateDone,
			filterCards,
		}
	},
})
