
import { defineComponent, ref } from 'vue'
import { useMeta } from 'vue-meta'
import { useStore } from '../../store'
import axios from 'axios'
import BreadCrumb from '../../components/BreadCrumb.vue'
import getDeviceToken from '@/functions/getDeviceToken'

type Character = {
	image_url: string
	min_invite_user_count: number
	name: string
	rank: number
	reduction_rate: number
}

export default defineComponent({
	components: {
		BreadCrumb,
	},
	setup() {
		useMeta({
			title: '友だち紹介ランキングについて',
			description: '友だち紹介ランキングについて',
		})

		// store
		const store = useStore()

		const characters = ref<Character[] | null>(null)

		// キャラクター取得
		const getCharacters = async () => {
			try {
				axios.defaults.headers.common['Authorization'] = 'Bearer ' + store.state.deviceToken
				const res = await axios.get('/api2/character/index')

				characters.value = res.data.characters
			} catch (error) {
				if (axios.isAxiosError(error)) {
					alert(error.response?.data.message)
				}
			}
		}

		// デバイストークン取得後にAPI起動
		const { deviceToken } = getDeviceToken()
		const start = async () => {
			await deviceToken()
			await getCharacters()
		}
		start()

		return {
			characters,
		}
	},
})
