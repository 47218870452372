
import { defineComponent, reactive } from 'vue'
import axios from 'axios'
import { useStore } from '../../store'
import { useRouter } from 'vue-router'
import { useMeta } from 'vue-meta'
import BreadCrumb from '../../components/BreadCrumb.vue'
import getDeviceToken from '@/functions/getDeviceToken'

export default defineComponent({
	components: {
		BreadCrumb,
	},
	setup() {
		// store
		const store = useStore()

		// router
		const router = useRouter()

		// state
		const state = reactive({
			phone_number: '',
			done_input_phone_number: false,
			auth_code: '',
			done_input_auth_code: false,
			mistake_auth_code: false,
		})

		// ログインしていないならトップに遷移
		if (store.state.isLoggedIn === false) {
			router.push({ name: 'Top' })
		}

		// デバイストークン取得
		const { deviceToken } = getDeviceToken()
		deviceToken()

		// （テスト用）特定のユーザーじゃなければトップへリダイレクト
		const getProfileAndBackHome = async () => {
			try {
				axios.defaults.headers.common['Authorization'] = 'Bearer ' + store.state.deviceToken
				const res = await axios.get('/api2/user/profile')
				if (res.data.user.id !== 75944 && res.data.user.id !== 979 && res.data.user.id !== 16216) {
					return router.push({ name: 'Top' })
				}
				console.log(res)
			} catch (error: any) {
				alert(error.response.data.message)
			}
		}
		// getProfileAndBackHome()

		const postPhoneNumber = async () => {
			try {
				const form = new FormData()
				form.append('phone_number', state.phone_number)

				axios.defaults.headers.common['Authorization'] = 'Bearer ' + store.state.deviceToken
				const res = await axios.post('/api2/user/update_phone_number', form)

				state.done_input_phone_number = true
			} catch (error) {
				if (axios.isAxiosError(error)) {
					if (error.response) {
						alert(error.response.data.message)
					}
				}
			}
		}

		const postAuthCode = async () => {
			try {
				const form = new FormData()
				form.append('auth_code', state.auth_code)

				axios.defaults.headers.common['Authorization'] = 'Bearer ' + store.state.deviceToken
				const res = await axios.post('/api2/user/auth_phone_number', form)

				state.done_input_auth_code = true
				state.mistake_auth_code = false
			} catch (error) {
				if (axios.isAxiosError(error)) {
					state.done_input_auth_code = true
					state.mistake_auth_code = true
				}
			}
		}

		// const retryAuthCode = () => {
		// 	state.done_input_auth_code = false
		// 	state.mistake_auth_code = false
		// }

		return {
			state,
			postPhoneNumber,
			postAuthCode,
			// retryAuthCode,
		}
	},
})
