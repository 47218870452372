
import { defineComponent, ref, watch } from 'vue'
import axios from 'axios'
import { reactive } from 'vue'
import { useStore } from '../../store'
import { useRouter } from 'vue-router'
import useVuelidate from '@vuelidate/core'
import { required, email, minLength, helpers } from '@vuelidate/validators'
import { useMeta } from 'vue-meta'
import BreadCrumb from '../../components/BreadCrumb.vue'
import VFacebookLogin from 'vue-facebook-login-component-next'
import getDeviceToken from '../../functions/getDeviceToken'

import { Swiper, SwiperSlide } from 'swiper/vue'
import 'swiper/swiper-bundle.min.css'
import 'swiper/swiper.min.css'
import 'swiper/components/navigation/navigation.min.css'
import 'swiper/components/pagination/pagination.min.css'
import SwiperCore, { Autoplay, Pagination, Navigation, Thumbs } from 'swiper/core'
SwiperCore.use([Autoplay, Pagination, Navigation, Thumbs])

// 半角英数字両方入力を必須にするバリデーションヘルパー追加
const alphaNumValidator = helpers.regex(/^[a-zA-Z0-9!_#%&@.\-+]{8,100}$/)

export default defineComponent({
	components: {
		// VFacebookLogin,
		BreadCrumb,
		Swiper,
		SwiperSlide,
	},
	setup() {
		useMeta({
			title: '会員登録',
			description: 'ポモチの会員登録画面です。ポモチでは店舗のお気に入り登録が可能です。また、自分の支払い方法や還元率を登録して還元率表示を自分に合わせて表示することができます。',
		})

		// vuex
		const store = useStore()

		// router
		const router = useRouter()

		// デバイストークン取得
		const { deviceToken } = getDeviceToken()
		deviceToken()

		if (store.state.isLoggedIn) {
			router.push({ name: 'Profile' })
		}

		// 都道府県
		const prefectures = [
			'未指定',
			'北海道',
			'青森県',
			'岩手県',
			'宮城県',
			'秋田県',
			'山形県',
			'福島県',
			'茨城県',
			'栃木県',
			'群馬県',
			'埼玉県',
			'千葉県',
			'東京都',
			'神奈川県',
			'新潟県',
			'富山県',
			'石川県',
			'福井県',
			'山梨県',
			'長野県',
			'岐阜県',
			'静岡県',
			'愛知県',
			'三重県',
			'滋賀県',
			'京都府',
			'大阪府',
			'兵庫県',
			'奈良県',
			'和歌山県',
			'鳥取県',
			'島根県',
			'岡山県',
			'広島県',
			'山口県',
			'徳島県',
			'香川県',
			'愛媛県',
			'高知県',
			'福岡県',
			'佐賀県',
			'長崎県',
			'熊本県',
			'大分県',
			'宮崎県',
			'鹿児島県',
			'沖縄県',
		]

		// 市区町村
		const cities = ref([])

		// 口コミ投票に行く場合
		// const toContribution = ref('none')
		// if (router.currentRoute.value.query.contribution_id !== '') {
		// 	toContribution.value = router.currentRoute.value.query.contribution_id as string
		// }

		// state作成
		const state = reactive({
			name: '',
			mail_address: '',
			password: '',
			birthday: '',
			sex_id: '',
			work_id: '',
			prefecture_id: '',
			city_id: '',
			agree_policy: undefined,
		})

		// スライダー
		const bannerSwiper: any = ref(null)
		const setBannerSwiper = (swiper: any) => {
			bannerSwiper.value = swiper
		}

		// バリデーション
		const rules = {
			name: { required },
			mail_address: { required, email },
			password: { required, min: minLength(8), alphaNumValidator },
			agree_policy: {
				checked: (val: any) => {
					return val
				},
			},
		}
		const v$ = useVuelidate(rules, state)

		// 新規登録
		const register = async () => {
			try {
				const form = new FormData()
				form.append('name', state.name)
				form.append('mail_address', state.mail_address)
				form.append('password', state.password)
				form.append('birthday', state.birthday)
				form.append('sex_id', state.sex_id)
				form.append('work_id', state.work_id)
				form.append('prefecture_id', state.prefecture_id)
				form.append('city_id', state.city_id)

				axios.defaults.headers.common['Authorization'] = 'Bearer ' + store.state.deviceToken
				const res = await axios.post('/api2/auth/signup', form)

				// vuexにログイン状態保存
				store.dispatch('getLoginUser')
				// トップに遷移
				return (location.href = `/web/dist/?signup_done=1`)
				// router.push({ name: 'Top', query: { signup_done: '1' } })
				// 店舗詳細ページから口コミ投票をするで遷移してきた場合とそうでない場合の分岐
				// if (toContribution.value === undefined) {
				// 	// プロフィールに遷移
				// 	router.push({ name: 'Profile' })
				// } else {
				// 	router.push({ name: 'ContributionAdd', params: { id: toContribution.value } })
				// }
			} catch (error: any) {
				alert(error.response.data.message)
			}
		}

		// fbオブジェクト
		const fb = reactive({
			FB: {},
			scope: {},
			access_token: '',
		})

		// facebookのAppIDを本番とテストで分ける
		let fbAppId: string | undefined = ''
		const checkEnvFbAppId = () => {
			const hostname = location.hostname
			if (hostname === 'www.ai-credit.com') {
				fbAppId = process.env.VUE_APP_FACEBOOK_APP_ID
			} else {
				fbAppId = process.env.VUE_APP_FACEBOOK_APP_ID_DEV
			}
		}
		checkEnvFbAppId()

		// facebookにログイン
		const facebookLogin = (res: any) => {
			if (res.status === 'connected') {
				store.state.fbToken = res.authResponse.accessToken
				fb.access_token = res.authResponse.accessToken
			}
		}

		// facebookアクセストークンでaicにログイン
		const aicFacebookLogin = async () => {
			try {
				const data = new FormData()
				data.append('fb_access_token', store.state.fbToken)
				axios.defaults.headers.common['Authorization'] = 'Bearer ' + store.state.deviceToken
				const res = await axios.post('/api2/auth/login_facebook', data)
				store.dispatch('getLoginFBUser')
				store.dispatch('getLoginUser')

				// トップに遷移
				return (location.href = `/web/dist/?signup_done=1`)
				// router.push({ name: 'Top', query: { signup_done: '1' } })
				// 店舗詳細ページから口コミ投票をするで遷移してきた場合とそうでない場合の分岐
				// if (toContribution.value === undefined) {
				// 	// プロフィールに遷移
				// 	router.push({ name: 'Profile' })
				// } else {
				// 	router.push({ name: 'ContributionAdd', params: { id: toContribution.value } })
				// }
			} catch (error: any) {
				alert(error.response.data.message)
			}
		}

		// facebookアクセストークンがあれば、aicログイン処理
		watch(
			() => fb.access_token,
			() => {
				if (fb.access_token !== '') {
					aicFacebookLogin()
				}
			},
		)

		const apple = reactive({
			signedIn: false,
			code: '',
		})

		const appleOnSuccess = (data: any) => {
			apple.signedIn = true
			apple.code = data.authorization.code
			appleLogin()
		}

		const appleOnSuccessLogout = () => {
			apple.signedIn = false
		}

		const appleOnFailure = (error: any) => {
			location.reload()
		}

		const appleLogin = async () => {
			try {
				const data = new URLSearchParams()
				data.append('authorization_code', apple.code)
				axios.defaults.headers.common['Authorization'] = 'Bearer ' + store.state.deviceToken
				const res = await axios.post('/api2/auth/login_apple', data)
				// vuexにログイン状態保存
				store.dispatch('getLoginUser')
				// トップに遷移
				return (location.href = `/web/dist/?signup_done=1`)
				// router.push({ name: 'Top', query: { signup_done: '1' } })
				// 店舗詳細ページから口コミ投票をするで遷移してきた場合とそうでない場合の分岐
				// if (toContribution.value === undefined) {
				// 	// プロフィールに遷移
				// 	router.push({ name: 'Profile' })
				// } else {
				// 	router.push({ name: 'ContributionAdd', params: { id: toContribution.value } })
				// }
			} catch (error: any) {
				alert(error.response.data.message)
			}
		}

		// 選択した都道府県の市区町村取得
		const getCities = async () => {
			try {
				axios.defaults.headers.common['Authorization'] = 'Bearer ' + store.state.deviceToken
				const res = await axios.get(`/api2/city/get_cities?prefecture_id=${Number(state.prefecture_id)}`)
				cities.value = res.data.cities
			} catch (error: any) {
				alert(error.response.data.message)
			}
		}

		// // facebookアクセストークンがあれば、aicログイン処理
		// watch(
		// 	() => apple.code,
		// 	() => {
		// 		if (apple.code !== '') {
		// 			appleLogin()
		// 		}
		// 	},
		// )
		// const resAppleData = async (context) => {
		// 	//リロードもされる想定で
		// 	if (context.req.method != 'POST') {
		// 		return { hoge: '' }
		// 	}
		// 	var body = ''
		// 	var temp = ''
		// 	while ((temp = context.req.read())) {
		// 		body += temp
		// 	}
		// 	var postParams = qs.parse(body)
		// 	//パラメータが空であることを想定してマージさせる
		// 	return { hoge: '', ...postParams }
		// }

		return {
			prefectures,
			cities,
			state,
			register,
			v$,
			facebookLogin,
			fbAppId,
			apple,
			appleOnSuccess,
			appleOnSuccessLogout,
			appleOnFailure,
			getCities,
			setBannerSwiper,
		}
	},
})
