
import { defineComponent, reactive } from 'vue'
import axios from 'axios'
import { useMeta } from 'vue-meta'
import { useStore } from '../../store'
import { useRouter } from 'vue-router'

import UsersSidebar from '../../components/UsersSidebar.vue'
import BreadCrumb from '../../components/BreadCrumb.vue'
import getDeviceToken from '@/functions/getDeviceToken'

export default defineComponent({
	components: {
		UsersSidebar,
		BreadCrumb,
	},
	setup() {
		useMeta({
			title: 'よく行くお店の利用金額',
			description: '毎月の利用金額を入れるとおすすめの支払い方法がレコメンドされます。',
		})

		// vuex
		const store = useStore()

		// router
		const router = useRouter()

		// ログインしていないならトップに遷移
		if (store.state.isLoggedIn === false) {
			router.push({ name: 'Top' })
		}

		// デバイストークン取得後にAPI起動
		const { deviceToken } = getDeviceToken()
		const start = async () => {
			await deviceToken()
			await getPayInfos()
		}
		start()

		// 利用情報格納用オブジェクト
		const informations: {
			data: []
			prices: { [key: string]: any }
		} = reactive({
			data: [],
			prices: {},
		})

		// 利用情報取得
		const getPayInfos = async () => {
			try {
				axios.defaults.headers.common['Authorization'] = 'Bearer ' + store.state.deviceToken
				const res = await axios.get('/api2/user_information/get')
				informations.data = res.data.user_information
				res.data.user_information.forEach((information: any) => {
					const code = information.code
					informations.prices[code] = information.value
				})
			} catch (error: any) {
				alert(error.response.data.message)
			}
		}

		// 利用している店舗更新判定用
		const updateDone = reactive({
			tof: false,
		})

		// 利用情報更新
		const updatePayInfos = async () => {
			try {
				// 更新判定をtrueに
				updateDone.tof = true

				const data = new URLSearchParams()
				let payInfo = '0'
				for (payInfo in informations.prices) {
					if (informations.prices[payInfo] === '') {
						informations.prices[payInfo] = '0'
					}
					data.append(payInfo, informations.prices[payInfo])
				}
				axios.defaults.headers.common['Authorization'] = 'Bearer ' + store.state.deviceToken
				const res = await axios.post('/api2/user_information/update', data)

				// 更新後最上部までスクロール
				window.scrollTo(0, 0)
			} catch (error: any) {
				alert(error.response.data.message)
			}
		}
		return {
			informations,
			updatePayInfos,
			updateDone,
		}
	},
})
