
import { defineComponent, ref } from 'vue'
import axios from 'axios'
import { reactive } from 'vue'
import { useMeta } from 'vue-meta'
import { useStore } from '../../store'
import UsersSidebar from '../../components/UsersSidebar.vue'
import BreadCrumb from '../../components/BreadCrumb.vue'
import { useRouter } from 'vue-router'
import getDeviceToken from '@/functions/getDeviceToken'

// 型定義
interface CustomURLSearchParams extends URLSearchParams {
	append(can_mail_magazine: string | number | boolean, value: string | number | undefined | Blob, fileName?: string): void
}

export default defineComponent({
	components: {
		UsersSidebar,
		BreadCrumb,
	},
	setup() {
		useMeta({
			title: 'お得な情報のメール通知設定',
			description: 'お得な情報のメール通知設定',
		})

		// router
		const router = useRouter()

		// store
		const store = useStore()

		// ログインしていないならトップに遷移
		if (store.state.isLoggedIn === false) {
			router.push({ name: 'Top' })
		}

		// デバイストークン取得後にAPI起動
		const { deviceToken } = getDeviceToken()
		const start = async () => {
			await deviceToken()
			await getProfile()
		}
		start()

		const notificationMailCheck = ref<boolean | number>(false)

		// 完了判定用
		const notificationDone = ref(false)

		// プロフィール取得
		const getProfile = async () => {
			try {
				axios.defaults.headers.common['Authorization'] = 'Bearer ' + store.state.deviceToken
				const res = await axios.get('/api2/user/profile')

				notificationMailCheck.value = res.data.user.can_mail_magazine
			} catch (error) {
				if (axios.isAxiosError(error)) {
					alert(error.response?.data.message)
				}
			}
		}

		// メール通知設定
		const updateNotificationMail = async () => {
			try {
				const data = new URLSearchParams() as CustomURLSearchParams
				data.append('can_mail_magazine', Number(notificationMailCheck.value))
				axios.defaults.headers.common['Authorization'] = 'Bearer ' + store.state.deviceToken
				const res = await axios.post('/api2/user/update_profile', data)

				// 完了判定をtrueに
				notificationDone.value = true
			} catch (error) {
				if (axios.isAxiosError(error)) {
					alert(error.response?.data.message)
				}
			}
		}

		return {
			notificationDone,
			notificationMailCheck,
			updateNotificationMail,
		}
	},
})
