
import { defineComponent, reactive, ref, watchEffect, computed } from 'vue'
import { useRoute, useRouter } from 'vue-router'
import { useStore } from '@/store'
import axios from 'axios'
import { useMeta } from 'vue-meta'
import getDeviceToken from '../../functions/getDeviceToken'

import { Swiper, SwiperSlide } from 'swiper/vue'
import 'swiper/swiper-bundle.min.css'
import 'swiper/swiper.min.css'
import 'swiper/components/navigation/navigation.min.css'
import 'swiper/components/pagination/pagination.min.css'
import SwiperCore, { Autoplay, Pagination, Navigation, Thumbs } from 'swiper/core'
SwiperCore.use([Autoplay, Pagination, Navigation, Thumbs])

export default defineComponent({
	components: {
		Swiper,
		SwiperSlide,
	},
	setup() {
		// useMeta({
		// 	title: 'ポイント還元を最大化するお得アプリ',
		// 	description:
		// 		'ポモチは誰でもお得になれる情報を配信しているお得情報アプリです。お店ごとにお得な支払い方法は異なります。あなたがよく利用するお店、交通手段、家族構成、ライフスタイルと生活圏によって決済方法選びは変わります。あなたのライフスタイルに合った決済選びをしましょう。ポモチはあなたが利用するお店の毎日のお得なキャンペーン情報やお得な情報もお届けします。',
		// })

		const branchName = ref('')
		const price = ref(1000)

		// 店舗詳細用オブジェクト
		const branchDetail = reactive({
			name: '',
			address: '',
			rate: '',
		})

		// キャンペーン用オブジェクト
		const campaigns = reactive({
			data: [],
			count: 5,
		})

		// スライダー
		const thumbsSwiper: any = ref(null)
		const setThumbsSwiper = (swiper: any) => {
			thumbsSwiper.value = swiper
		}
		const slideSwiper: any = ref(null)
		const setSlideSwiper = (swiper: any) => {
			slideSwiper.value = swiper
		}

		// スライドが変更されたときにプランを取得
		const changePlanWhenSlideChange = () => {
			if (thumbsSwiper.value.clickedIndex === 0) {
				changePrice(1000)
				price.value = 1000
			}
			if (thumbsSwiper.value.clickedIndex === 1) {
				changePrice(2000)
				price.value = 2000
			}
			if (thumbsSwiper.value.clickedIndex === 2) {
				changePrice(3000)
				price.value = 3000
			}
			if (thumbsSwiper.value.clickedIndex === 3) {
				changePrice(4000)
				price.value = 4000
			}
			if (thumbsSwiper.value.clickedIndex === 4) {
				changePrice(5000)
				price.value = 5000
			}
			if (thumbsSwiper.value.clickedIndex === 5) {
				changePrice(6000)
				price.value = 6000
			}
			if (thumbsSwiper.value.clickedIndex === 6) {
				changePrice(7000)
				price.value = 7000
			}
			if (thumbsSwiper.value.clickedIndex === 7) {
				changePrice(8000)
				price.value = 8000
			}
			if (thumbsSwiper.value.clickedIndex === 8) {
				changePrice(9000)
				price.value = 9000
			}
			if (thumbsSwiper.value.clickedIndex === 9) {
				changePrice(10000)
				price.value = 10000
			}
		}

		// スライドが変更されたときにプランを取得
		const changePlanWhenSlideMainChange = () => {
			if (slideSwiper.value.activeIndex === 0) {
				changePrice(1000)
				price.value = 1000
			}
			if (slideSwiper.value.activeIndex === 1) {
				changePrice(2000)
				price.value = 2000
			}
			if (slideSwiper.value.activeIndex === 2) {
				changePrice(3000)
				price.value = 3000
			}
			if (slideSwiper.value.activeIndex === 3) {
				changePrice(4000)
				price.value = 4000
			}
			if (slideSwiper.value.activeIndex === 4) {
				changePrice(5000)
				price.value = 5000
			}
			if (slideSwiper.value.activeIndex === 5) {
				changePrice(6000)
				price.value = 6000
			}
			if (slideSwiper.value.activeIndex === 6) {
				changePrice(7000)
				price.value = 7000
			}
			if (slideSwiper.value.activeIndex === 7) {
				changePrice(8000)
				price.value = 8000
			}
			if (slideSwiper.value.activeIndex === 8) {
				changePrice(9000)
				price.value = 9000
			}
			if (slideSwiper.value.activeIndex === 9) {
				changePrice(10000)
				price.value = 10000
			}
		}

		// router
		const router = useRouter()

		// route
		const route = useRoute()

		// store
		const store = useStore()

		const details = reactive({
			data: [],
		})

		const pay = reactive({
			title: '',
		})
		const options = reactive({
			data: {},
		})

		// お気に入り状態
		const isFavorite = ref()

		// お気に入り登録
		const registerFavorite = async () => {
			try {
				const branchId = router.currentRoute.value.params.id + ''
				const params = new FormData()
				params.append('branch_id', branchId)

				axios.defaults.headers.common['Authorization'] = 'Bearer ' + store.state.deviceToken
				const res = await axios.post('/api2/branch/favorite', params)
				isFavorite.value = true
			} catch (error: any) {
				alert(error.response.data.message)
			}
		}

		// お気に入り解除
		const unregisterFavorite = async () => {
			try {
				const branchId = router.currentRoute.value.params.id + ''
				const params = new FormData()
				params.append('branch_id', branchId)

				axios.defaults.headers.common['Authorization'] = 'Bearer ' + store.state.deviceToken
				const res = await axios.post('/api2/branch/un_favorite', params)

				isFavorite.value = false
			} catch (error: any) {
				alert(error.response.data.message)
			}
		}

		const changePrice = async (price: number) => {
			try {
				const branch = router.currentRoute.value.params.id

				axios.defaults.headers.common['Authorization'] = 'Bearer ' + store.state.deviceToken
				const response = await axios.get('/api2/branch/detail/' + branch + '?price=' + price)
				const res = response.data

				// お気に入り状態取得
				isFavorite.value = res.is_favorite

				// 店名取得
				branchName.value = res.name

				// 絞り込みプルダウン作成
				const allItems = []
				for (let i = 0; i < response.data.plans.length; i++) {
					for (let j = 0; j < response.data.plans_item_codes.length; j++) {
						if (response.data.plans_item_codes[j].code === response.data.plans[i].item_codes[0]) {
							allItems.push({
								code: response.data.plans[i].item_codes[0],
								title: response.data.plans_item_codes[j].title,
							})
						}
					}
				}
				// 重複を取り除く処理
				options.data = allItems.filter((element, index, self) => self.findIndex((e) => e.code === element.code && e.title === element.title) === index)
				const dataLength = response.data.plans.length
				// 絞り込み
				if (pay.title !== '') {
					let count = 0
					for (let i = 0; i < dataLength; i++) {
						if (response.data['plans'][count].item_codes[0] !== pay.title) {
							res['plans'].splice(count, 1)
						} else {
							count = count + 1
						}
					}
				}

				// 還元率で降順ソート
				res['plans'].sort(function (a: any, b: any) {
					if (a.reduction_rate > b.reduction_rate) return -1
					if (a.reduction_rate < b.reduction_rate) return 1
					return 0
				})

				// 還元率上位5つ以外削除
				res['plans'].splice(5)

				// 支払い方法
				for (let i = 0; i < res['plans'].length; i++) {
					// 還元額計算
					if (res['plans'][i] != null) {
						res['plans'][i].reduction_prace = Math.floor(Number(res['plans'][i].reduction_rate) * price)

						// 還元率を100%基準に
						res['plans'][i].reduction_rate = res['plans'][i].reduction_rate * 100
						res['plans'][i].reduction_rate = Math.round(Number(res['plans'][i].reduction_rate) * 100) / 100
					}
					// 支払い種類の文字列生成
					res['plans'][i].item_name = []
					for (let j = 0; j < res['plans'][i].items.length; j++) {
						res['plans'][i].item_name.push(res['plans'][i].items[j])
					}
					const tmpStr = res['plans'][i].item_name.join(',')
					res['plans'][i].item_name_str = tmpStr.replace(',', ' + ')
				}

				branchDetail.name = res.name
				branchDetail.address = res.address
				branchDetail.rate = res.plans[0].reduction_rate

				details.data = res
				campaigns.data = res.campaigns
			} catch (error) {
				router.push({ name: 'NotFound' })
			}
		}

		// デバイストークン取得後にAPI起動
		const { deviceToken } = getDeviceToken()
		const start = async () => {
			await deviceToken()
			await changePrice(1000)
		}
		start()

		// 口コミを追加押下
		const validModal = () => {
			const popup = document.getElementById('report-popup01')
			popup!.classList.add('is-show')
		}
		// 口コミを追加「はい」押下
		const validYes = async () => {
			try {
				const data = new URLSearchParams()
				const branchId = router.currentRoute.value.params.id + ''
				data.append('branch_id', branchId)
				data.append('is_valid', '0')
				axios.defaults.headers.common['Authorization'] = 'Bearer ' + store.state.deviceToken
				const response = await axios.post('/api2/contribution/add', data)
			} catch (error: any) {
				alert(error.response.data.message)
			}

			const popup = document.getElementById('report-popup01')
			popup!.classList.remove('is-show')
		}
		// 閉店通報「いいえ」押下
		const validNo = () => {
			const popup = document.getElementById('report-popup01')
			popup!.classList.remove('is-show')
		}

		const validModalClose = () => {
			const popup = document.getElementById('report-popup01')
			popup!.classList.remove('is-show')
		}

		// 口コミを追加押下
		const contributionModal = () => {
			if (!store.state.isLoggedIn) {
				const popup = document.getElementById('report-popup04')
				popup!.classList.add('is-show')
			} else {
				router.push({ name: 'ContributionAdd' })
			}
		}
		// 口コミを追加「はい」押下
		const contributionYes = () => {
			const popup = document.getElementById('report-popup04')
			popup!.classList.remove('is-show')
			router.push({ name: 'Signup', query: { contribution_id: route.params.id } })
		}
		// 口コミを追加「いいえ」押下
		const contributionNo = () => {
			const popup = document.getElementById('report-popup04')
			popup!.classList.remove('is-show')
		}
		// 口コミを追加枠外押下
		const contributionModalClose = () => {
			const popup = document.getElementById('report-popup04')
			popup!.classList.remove('is-show')
		}

		// お気に入り押下
		const favoriteModal = () => {
			if (!store.state.isLoggedIn) {
				const popup = document.getElementById('report-popup05')
				popup!.classList.add('is-show')
			} else {
				registerFavorite()
			}
		}
		// お気に入り「はい」押下
		const favoriteYes = () => {
			const popup = document.getElementById('report-popup05')
			popup!.classList.remove('is-show')
			router.push({ name: 'Login' })
		}
		// お気に入り「いいえ」押下
		const favoriteNo = () => {
			const popup = document.getElementById('report-popup05')
			popup!.classList.remove('is-show')
		}
		// お気に入り枠外押下
		const favoriteModalClose = () => {
			const popup = document.getElementById('report-popup05')
			popup!.classList.remove('is-show')
		}

		// 重複通報押下
		const overlappingModal = () => {
			const popup = document.getElementById('report-popup02')
			popup!.classList.add('is-show')
		}
		// 重複通報「はい」押下
		const overlappingYes = async () => {
			try {
				const data = new URLSearchParams()
				const branchId = router.currentRoute.value.params.id + ''
				data.append('branch_id', branchId)
				data.append('is_overlapping', '1')
				axios.defaults.headers.common['Authorization'] = 'Bearer ' + store.state.deviceToken
				const response = await axios.post('/api2/contribution/add', data)
			} catch (error: any) {
				alert(error.response.data.message)
			}

			const popup = document.getElementById('report-popup02')
			popup!.classList.remove('is-show')
		}
		// 重複通報「いいえ」押下
		const overlappingNo = () => {
			const popup = document.getElementById('report-popup02')
			popup!.classList.remove('is-show')
		}

		const overlappingModalClose = () => {
			const popup = document.getElementById('report-popup02')
			popup!.classList.remove('is-show')
		}

		// キャンペーン詳細
		const campaignModal = (data: any) => {
			const popup = document.getElementById('report-popup03')
			popup!.classList.add('is-show')

			document.getElementById('camp_title')!.innerHTML = data.title
			document.getElementById('camp_description')!.innerHTML = data.description
			if (data.start_at !== null && data.end_at !== null) {
				document.getElementById('camp_date')!.innerHTML = data.start_at + '～' + data.end_at
			} else if (data.start_at === null) {
				document.getElementById('camp_date')!.innerHTML = '～' + data.end_at
			} else if (data.end_at === null) {
				document.getElementById('camp_date')!.innerHTML = data.start_at + '～'
			} else {
				document.getElementById('camp_date')!.innerHTML = ''
			}

			const elementLink = document.getElementById('camp_link') as HTMLAnchorElement
			elementLink!.href = data.blog_url
		}

		const campaignClose = () => {
			const popup = document.getElementById('report-popup03')
			popup!.classList.remove('is-show')
		}

		const sliceCampaigns = computed(() => campaigns.data.slice(0, campaigns.count))

		const isMore = () => {
			campaigns.count += 5
		}

		const { meta } = useMeta({
			title: '',
			description: '',
			og: {
				title: '',
				description: '',
				url: window.location.href,
				image: 'https://www.ai-credit.com/apple-touch-icon.png',
				type: 'website',
				site_name: 'ポモチ',
				locale: 'ja',
			},
			twitter: {
				title: '',
				description: '',
				image: 'https://www.ai-credit.com/android-chrome-512x512.png',
			},
			bodyAttrs: {
				class: 'shops_common',
			},
			link: { rel: 'canonical', href: '' },
		})

		watchEffect(() => {
			meta.title = `${branchDetail.name}のお得な支払い方法・ポイント還元率`
			meta.description = `${branchDetail.name}の店舗情報、支払い方法の確認はポモチ。クレジットカード・コード決済は利用可能か。${branchDetail.name}の支払いで還元率のいい決済情報をまとめています。最大${branchDetail.rate}%の還元率も！ポモチでお得な決済方法をみつけよう！${branchDetail.address}`
			meta.og.title = `${branchDetail.name}-新着情報`
			meta.og.description = `${branchDetail.name}の店舗情報、支払い方法の確認はポモチ。クレジットカード・コード決済は利用可能か。${branchDetail.name}の支払いで還元率のいい決済情報をまとめています。最大${branchDetail.rate}%の還元率も！ポモチでお得な決済方法をみつけよう！${branchDetail.address}`
			meta.twitter.title = `${branchDetail.name}-新着情報`
			meta.twitter.description = `${branchDetail.name}の店舗情報、支払い方法の確認はポモチ。クレジットカード・コード決済は利用可能か。${branchDetail.name}の支払いで還元率のいい決済情報をまとめています。最大${branchDetail.rate}%の還元率も！ポモチでお得な決済方法をみつけよう！${branchDetail.address}`
			meta.link.href = `${document.URL}`
		})

		return {
			router,
			details,
			pay,
			options,
			sliceCampaigns,
			isMore,
			branchName,
			isFavorite,
			registerFavorite,
			unregisterFavorite,
			price,
			changePrice,
			validModal,
			validYes,
			validNo,
			validModalClose,
			overlappingModal,
			overlappingYes,
			overlappingNo,
			overlappingModalClose,
			campaignModal,
			campaignClose,
			contributionModal,
			contributionYes,
			contributionNo,
			contributionModalClose,
			favoriteModal,
			favoriteYes,
			favoriteNo,
			favoriteModalClose,
			Thumbs,
			thumbsSwiper,
			slideSwiper,
			setThumbsSwiper,
			setSlideSwiper,
			changePlanWhenSlideChange,
			changePlanWhenSlideMainChange,
		}
	},
})
