
import { defineComponent, reactive, computed, watchEffect } from 'vue'
import axios from 'axios'
import { useRoute, useRouter } from 'vue-router'
import { useStore } from '../../store'
import { useMeta } from 'vue-meta'
import BreadCrumb from '../../components/BreadCrumb.vue'
import getDeviceToken from '@/functions/getDeviceToken'

// 型定義
interface PaysInfo {
	code: string
	name: string
	value: string | number | null
}

export default defineComponent({
	components: {
		BreadCrumb,
	},
	setup() {
		const { meta } = useMeta({
			title: '（店舗名）の口コミ投票',
			description: '（店舗名）の口コミ投票はこちらから行えます。（店舗住所）の（店舗名）で支払い可能な決済サービスを投票しましょう。',
		})

		// route
		const route = useRoute()

		// router
		const router = useRouter()

		// vuex
		const store = useStore()

		// ログインしていないならトップに遷移
		if (store.state.isLoggedIn === false) {
			router.push({ name: 'Top' })
		}

		// デバイストークン取得後にAPI起動
		const { deviceToken } = getDeviceToken()
		const start = async () => {
			await deviceToken()
			await getContribution()
			await getBranch()
		}
		start()
		// テスト完了までトップに戻す
		// router.push({ name: 'Top' })

		// クレジットカード
		const creditCards: {
			data: []
			info: { [key: string]: any }
		} = reactive({
			data: [],
			info: {},
		})

		// スマートフォン決済
		const codePays: {
			data: []
			info: { [key: string]: any }
		} = reactive({
			data: [],
			info: {},
		})

		// 非接触決済
		const noTouchPays: {
			data: []
			info: { [key: string]: any }
		} = reactive({
			data: [],
			info: {},
		})

		// その他の支払い方法
		const otherPayments: {
			data: []
			info: { [key: string]: any }
		} = reactive({
			data: [],
			info: {},
		})

		// 口コミ取得
		const getContribution = async () => {
			try {
				axios.defaults.headers.common['Authorization'] = 'Bearer ' + store.state.deviceToken
				const res = await axios.get(`/api2/contribution/questions/${route.params.id}`)
				// APIで取得したクレジットカードの口コミをcreditCardsに保存
				creditCards.data = res.data[2].items
				let creditCard: PaysInfo
				for (creditCard of creditCards.data) {
					const code = creditCard.code
					creditCards.info[code] = undefined
				}

				// APIで取得したスマートフォン決済の口コミをcodePaysに保存
				codePays.data = res.data[0].items
				let codePay: PaysInfo
				for (codePay of codePays.data) {
					const code = codePay.code
					codePays.info[code] = undefined
				}

				// APIで取得した非接触決済の口コミをnoTouchPaysに保存
				noTouchPays.data = res.data[1].items
				let noTouchPay: PaysInfo
				for (noTouchPay of noTouchPays.data) {
					const code = noTouchPay.code
					noTouchPays.info[code] = undefined
				}

				// APIで取得したその他の支払い方法の口コミをotherPaymentsに保存
				otherPayments.data = res.data[3].items
				let otherPayment: PaysInfo
				for (otherPayment of otherPayments.data) {
					const code = otherPayment.code
					otherPayments.info[code] = undefined
				}
			} catch (error: any) {
				// alert(error.response.data.message)
			}
		}

		const sendContribution = async () => {
			try {
				const data = new URLSearchParams()

				// branch_idをパスから取得してdataに追加
				const branchId = route.params.id + ''
				data.append('branch_id', branchId)

				// 口コミが入力されていたらdataに追加
				let creditCard: string | undefined = undefined
				for (creditCard in creditCards.info) {
					if (creditCards.info[creditCard] !== undefined) {
						data.append(creditCard, creditCards.info[creditCard])
					}
				}
				let codePay: string | undefined = undefined
				for (codePay in codePays.info) {
					if (codePays.info[codePay] !== undefined) {
						data.append(codePay, codePays.info[codePay])
					}
				}
				let noTouchPay: string | undefined = undefined
				for (noTouchPay in noTouchPays.info) {
					if (noTouchPays.info[noTouchPay] !== undefined) {
						data.append(noTouchPay, noTouchPays.info[noTouchPay])
					}
				}
				let otherPayment: string | undefined = undefined
				for (otherPayment in otherPayments.info) {
					if (otherPayments.info[otherPayment] !== undefined) {
						data.append(otherPayment, otherPayments.info[otherPayment])
					}
				}
				axios.defaults.headers.common['Authorization'] = 'Bearer ' + store.state.deviceToken
				const res = await axios.post('/api2/contribution/add', data)
				router.push({
					name: 'ContributionAnswer',
					query: { add: 'added' },
				})
			} catch (error: any) {
				alert(error.response.data.message)
			}
		}

		// ログインしていなかったらモーダルを出す
		const validModal = () => {
			const popup = document.getElementById('report-popup01')
			popup!.classList.add('is-show')
		}
		const validYes = () => {
			router.push({ name: 'Signup' })
		}
		const validNo = () => {
			const popup = document.getElementById('report-popup01')
			popup!.classList.remove('is-show')
		}
		const validModalClose = () => {
			const popup = document.getElementById('report-popup01')
			popup!.classList.remove('is-show')
		}

		const branch = reactive({
			name: '',
			address: '',
		})
		const getBranch = async () => {
			axios.defaults.headers.common['Authorization'] = 'Bearer ' + store.state.deviceToken
			const res = await axios.get(`/api2/branch/detail/${route.params.id}?price=1000`)
			branch.name = res.data.name
			branch.address = res.data.address
		}

		watchEffect(() => {
			meta.title = `${branch.name}の口コミ投票`
			meta.description = `${branch.name}の口コミ投票はこちらから行えます。${branch.address}の${branch.name}で支払い可能な決済サービスを投票しましょう。`
		})

		return {
			store,
			creditCards,
			codePays,
			noTouchPays,
			otherPayments,
			sendContribution,
			validModal,
			validModalClose,
			validYes,
			validNo,
		}
	},
})
