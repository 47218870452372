
import { defineComponent, ref } from 'vue'
import { useMeta } from 'vue-meta'
import axios from 'axios'
import { useStore } from '../../store'
import { useRouter } from 'vue-router'

type OmochiGamesData = {
	description: string
	omochi_games: OmochiGame[]
}

type OmochiGame = {
	description: string
	game_url: null | string
	id: number
	image_url: string
	title: string
}

export default defineComponent({
	setup() {
		useMeta({
			title: 'ゲームでポイントを貯める',
			description: 'ゲームアプリを無課金でプレイしてポイントを貯めることもできます。',
		})

		// store
		const store = useStore()

		// router
		const router = useRouter()

		const omochi_game_data = ref<OmochiGamesData>()

		const authenticated_phone_number = ref(0)

		// プロフィール取得
		const getProfile = async () => {
			try {
				axios.defaults.headers.common['Authorization'] = 'Bearer ' + store.state.deviceToken
				const res = await axios.get('/api2/user/profile')
				authenticated_phone_number.value = res.data.user.authenticated_phone_number
			} catch (error) {
				// if (axios.isAxiosError(error)) {
				// 	alert(error.response?.data.message)
				// }
			}
		}

		if (store.state.isLoggedIn) {
			getProfile()
		}

		// url取得
		const getOmochiGame = async () => {
			try {
				axios.defaults.headers.common['Authorization'] = 'Bearer ' + store.state.deviceToken
				const res = await axios.get('/api2/omochi_game/index')
				omochi_game_data.value = res.data
			} catch (error) {
				// if (axios.isAxiosError(error)) {
				// 	if (error.response?.data.message.startsWith('電話番号認証')) {
				// 		authenticated_phone_number.value = 0
				// 	}
				// }
			}
		}
		getOmochiGame()

		// 公式サイトへ押下
		const checkAuthModal = (url: string | null) => {
			if (!store.state.isLoggedIn) {
				const popup = document.getElementById('report-popup01')
				popup!.classList.add('is-show')
				return
			}

			if (authenticated_phone_number.value === 0) {
				const popup = document.getElementById('report-popup01')
				popup!.classList.add('is-show')
				return
			}

			if (typeof url !== 'string') {
				return
			}

			return open(url, '_blank')
		}
		// ポップアップ「はい」押下
		const checkAuthYes = () => {
			const popup = document.getElementById('report-popup01')
			popup!.classList.remove('is-show')
			if (!store.state.isLoggedIn) {
				return router.push({ name: 'Login' })
			} else {
				return router.push({ name: 'UserCertification' })
			}
		}

		// ポップアップ「いいえ」または枠外押下
		const checkAuthModalClose = () => {
			const popup = document.getElementById('report-popup01')
			popup!.classList.remove('is-show')
		}

		return {
			store,
			omochi_game_data,
			authenticated_phone_number,
			checkAuthModal,
			checkAuthYes,
			checkAuthModalClose,
		}
	},
})
