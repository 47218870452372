
import { defineComponent, reactive, watchEffect } from 'vue'
import axios from 'axios'
import { useRoute, useRouter } from 'vue-router'
import { useStore } from '@/store'
import { useMeta } from 'vue-meta'
import getDeviceToken from '../../functions/getDeviceToken'

type AffiliateCategoryTag = {
	id: number
	name: string
}

export default defineComponent({
	setup() {
		// route
		const route = useRoute()

		// router
		const router = useRouter()

		// store
		const store = useStore()

		// state
		const state = reactive({
			affiliate_category_tags: [],
			filtered_affiliate_category_tags: [],
			affiliate_item: [],
			affiliate_item_title: '',
			authenticated_phone_number: 0,
		})

		// // お知らせ詳細用オブジェクト
		// const noticeDetail = reactive({
		// 	title: '',
		// 	description: '',
		// 	type: '',
		// 	url: '',
		// })

		// （テスト用）特定のユーザーじゃなければトップへリダイレクト
		const getProfileAndBackHome = async () => {
			try {
				axios.defaults.headers.common['Authorization'] = 'Bearer ' + store.state.deviceToken
				const res = await axios.get('/api2/user/profile')
				state.authenticated_phone_number = res.data.user.authenticated_phone_number
				if (res.data.user.id !== 75944 && res.data.user.id !== 979 && res.data.user.id !== 16216) {
					return router.push({ name: 'Top' })
				}
				console.log(res)
			} catch (error: any) {
				alert(error.response.data.message)
			}
		}
		// getProfileAndBackHome()

		// 案件取得
		const getAffiliateItem = async () => {
			try {
				// 案件詳細取得
				axios.defaults.headers.common['Authorization'] = 'Bearer ' + store.state.deviceToken
				const res = await axios.get(`/api2/affiliate_item/detail/${route.params.id}`)
				// const res = await axios.get(`/api2/affiliate_item/index`)

				state.affiliate_category_tags = res.data.affiliate_category_tags
				state.affiliate_item = res.data.affiliate_item
				state.affiliate_item_title = res.data.affiliate_item.title

				state.filtered_affiliate_category_tags = state.affiliate_category_tags.filter((item: AffiliateCategoryTag) => {
					return res.data.affiliate_item.affiliate_category_tag_ids.includes(item.id)
				})
			} catch (error) {
				if (axios.isAxiosError(error)) {
					router.push({ name: 'NotFound' })
				}
			}
		}

		// プロフィール取得
		const getProfile = async () => {
			try {
				axios.defaults.headers.common['Authorization'] = 'Bearer ' + store.state.deviceToken
				const res = await axios.get('/api2/user/profile')

				state.authenticated_phone_number = res.data.user.authenticated_phone_number
			} catch (error) {
				if (axios.isAxiosError(error)) {
					alert(error.response?.data.message)
				}
			}
		}

		// デバイストークン取得後にAPI起動
		const { deviceToken } = getDeviceToken()
		const start = async () => {
			await deviceToken()
			if (store.state.isLoggedIn === true) {
				await getProfile()
			}
			await getAffiliateItem()
		}
		start()

		// 公式サイトへ押下
		const checkAuthModal = (url: string) => {
			if (!store.state.isLoggedIn) {
				const popup = document.getElementById('report-popup01')
				popup!.classList.add('is-show')
				return
			}

			if (state.authenticated_phone_number === 0) {
				const popup = document.getElementById('report-popup01')
				popup!.classList.add('is-show')
				return
			}

			return open(url, '_blank')
		}
		// ポップアップ「はい」押下
		const checkAuthYes = () => {
			const popup = document.getElementById('report-popup01')
			popup!.classList.remove('is-show')
			if (!store.state.isLoggedIn) {
				return router.push({ name: 'Login' })
			} else {
				return router.push({ name: 'UserCertification' })
			}
		}

		// ポップアップ「いいえ」または枠外押下
		const checkAuthModalClose = () => {
			const popup = document.getElementById('report-popup01')
			popup!.classList.remove('is-show')
		}

		// メタ情報
		const { meta } = useMeta({
			title: '',
			description: '',
		})

		watchEffect(() => {
			meta.title = `${state.affiliate_item_title}でポイントを貯める`
			meta.description = `ポモチでは広告を利用することでポモチポイントが貯まります。${state.affiliate_item_title}の広告をご紹介します。`
		})
		return {
			state,
			store,
			checkAuthModal,
			checkAuthYes,
			checkAuthModalClose,
		}
	},
})
