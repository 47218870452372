import { useStore } from '../store'
import axios from 'axios'
export default function () {
	const deviceToken = async () => {
		// store
		const store = useStore()

		if (store.state.deviceToken) {
			try {
				// サーバー負荷軽減のため前回デバイストークン取得API起動時から10分以内だったら返す
				const nowDateTime = new Date().getTime() / (1000 * 60)
				if (nowDateTime - store.state.ignitionGetDeviceTokenTime <= 10) {
					return
				}

				let device = ''
				let os = ''
				if (navigator.userAgent.match(/(iPhone)/i)) {
					device = 'iPhone'
					os = 'iOS'
				} else if (navigator.userAgent.match(/(Android)/i)) {
					device = 'Android'
					os = 'Android'
				} else if (navigator.userAgent.match(/(iPad)/i)) {
					device = 'iPad'
					os = 'iOS'
				} else if (navigator.userAgent.match(/(Macintosh)/i)) {
					device = 'Mac'
					os = 'PC'
				} else if (navigator.userAgent.match(/(Win)/i)) {
					device = 'Windows'
					os = 'PC'
				}
				const params = new URLSearchParams({
					terminal: device,
					os: os,
					os_version: '10.0.0',
					app_version: '1.0.0',
				})
				axios.defaults.headers.common['Authorization'] = 'Bearer ' + store.state.deviceToken
				const res = await axios.put('/api2/device/update', params)
				store.dispatch('getDeviceToken', {
					deviceToken: res.data.device_token,
				})

				// API起動時刻を保存
				store.dispatch('getIgnitionGetDeviceTokenTime')

				return res.data.device_token
			} catch (error) {
				alert('リロードしてください')
			}
		} else {
			try {
				let device = ''
				let os = ''
				if (navigator.userAgent.match(/(iPhone)/i)) {
					device = 'iPhone'
					os = 'iOS'
				} else if (navigator.userAgent.match(/(Android)/i)) {
					device = 'Android'
					os = 'Android'
				} else if (navigator.userAgent.match(/(iPad)/i)) {
					device = 'iPad'
					os = 'iOS'
				} else if (navigator.userAgent.match(/(Macintosh)/i)) {
					device = 'Mac'
					os = 'PC'
				} else if (navigator.userAgent.match(/(Win)/i)) {
					device = 'Windows'
					os = 'PC'
				}
				const params = new URLSearchParams({
					terminal: device,
					os: os,
					os_version: '10.0.0',
					app_version: '1.0.0',
				})

				const res = await axios.post('/api2/device/add', params)

				// ユーザーのログイン状態を未ログインに
				store.dispatch('changeLoginUserState')

				// デバイストークンをクッキーに保存
				store.dispatch('getDeviceToken', {
					deviceToken: res.data.device_token,
				})

				// API起動時刻を保存
				store.dispatch('getIgnitionGetDeviceTokenTime')

				return res.data.device_token
			} catch (error) {
				alert('リロードしてください')
			}
		}
	}

	return { deviceToken }
}
