
import { defineComponent } from 'vue'
import Header from './components/Header.vue'
// import HeaderSp from './components/HeaderSp.vue'
// import NewHeader from './components/NewHeader.vue'
// import NewHeaderSp from './components/NewHeaderSp.vue'
import DownloadApp from './components/DownloadApp.vue'
import Footer from './components/Footer.vue'
import { useMeta } from 'vue-meta'
import { useRoute } from 'vue-router'

export default defineComponent({
	name: 'App',
	components: {
		Header,
		// HeaderSp,
		// NewHeader,
		// NewHeaderSp,
		DownloadApp,
		Footer,
	},
	setup() {
		useMeta({
			title: '',
			description: '',
			htmlAttrs: { lang: 'ja' },
		})

		const route = useRoute()

		return {
			route,
		}
	},
})
