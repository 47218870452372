
import { defineComponent, reactive, watchEffect } from 'vue'
import axios, { AxiosResponse } from 'axios'
import { useStore } from '../../store'
import { useRoute, useRouter } from 'vue-router'
import { useMeta } from 'vue-meta'
import BreadCrumb from '../../components/BreadCrumb.vue'
import UsersSidebar from '../../components/UsersSidebar.vue'
import getDeviceToken from '@/functions/getDeviceToken'

type ResData = {
	gifts: Gift[]
}

type State = {
	gift?: Gift
	error_message?: string
	paypay_link?: boolean
	character_url: string
}

type Gift = {
	description: string
	description_detail: string
	exchange_timing: string
	id: number
	image_url: string
	name: string
	prices: Price[]
	type?: number | string
	withdrawal_product_id: number | null
	withdrawal_product_type: string | null
}

type Price = {
	can_exchange: boolean
	description: string
	exchange_title: string
	point: number
	price: number
	rate: number
	unit: string
}

export default defineComponent({
	components: {
		BreadCrumb,
		UsersSidebar,
	},
	setup() {
		// store
		const store = useStore()

		// router
		const router = useRouter()

		// route
		const route = useRoute()

		// state
		const state = reactive<State>({
			gift: undefined,
			error_message: '',
			character_url: '',
		})

		// ログインしていないならトップに遷移
		if (store.state.isLoggedIn === false) {
			router.push({ name: 'Top' })
		}

		// デバイストークン取得後にAPI起動
		const { deviceToken } = getDeviceToken()
		const start = async () => {
			await deviceToken()
			await getProfile()
			await getGifts()
		}
		start()

		// （テスト用）特定のユーザーじゃなければトップへリダイレクト
		const getProfileAndBackHome = async () => {
			try {
				axios.defaults.headers.common['Authorization'] = 'Bearer ' + store.state.deviceToken
				const res = await axios.get('/api2/user/profile')
				if (res.data.user.id !== 75944 && res.data.user.id !== 979 && res.data.user.id !== 16216) {
					return router.push({ name: 'Top' })
				}
			} catch (error: any) {
				alert(error.response.data.message)
			}
		}
		// getProfileAndBackHome()

		// プロフィール取得
		const getProfile = async () => {
			try {
				axios.defaults.headers.common['Authorization'] = 'Bearer ' + store.state.deviceToken
				const res = await axios.get('/api2/user/profile')

				state.character_url = res.data.user.character.image_url

				state.paypay_link = res.data.user.linked_paypay
			} catch (error) {
				if (axios.isAxiosError(error)) {
					alert(error.response?.data.message)
				}
			}
		}

		// ギフトの交換情報を取得
		const getGifts = async () => {
			try {
				axios.defaults.headers.common['Authorization'] = 'Bearer ' + store.state.deviceToken
				const res: AxiosResponse<ResData> = await axios.get('/api2/gift/index')
				// console.log(res.data.gifts.filter((gift) => gift.id === 1))

				// res.data.gifts.forEach((gift) => {
				// 	console.log(gift)

				// 	if (gift.id === 1) {
				// 		state.gift = gift
				// 	}
				// })

				for (const property in res.data.gifts) {
					if (res.data.gifts[property].id === Number(route.params.id)) {
						state.gift = res.data.gifts[property]
						break
					}
				}
			} catch (error) {
				if (axios.isAxiosError(error)) {
					alert(error.response?.data.message)
				}
			}
		}

		// PayPay連携解除
		const unlinkPayPay = async () => {
			const check = confirm('PayPayとの連携を解除してよろしいですか？')
			if (!check) {
				return
			}
			try {
				axios.defaults.headers.common['Authorization'] = 'Bearer ' + store.state.deviceToken
				const res = await axios.post('/api2/auth/unlink_paypay')
				state.paypay_link = false
			} catch (error) {
				if (axios.isAxiosError(error)) {
					alert(error.response?.data.message)
				}
			}
		}

		// ポモチポイントをギフトに変換
		const exchangePoint = async (price: number) => {
			try {
				// PayPayとの連携が済んでいなかったら連携リンク作成
				if (state.gift?.type === 1) {
					if (!state.paypay_link) {
						const check = confirm('PayPayとの連携が必要です。連携後、再度交換手続きをお願いします。')
						if (check) {
							return await getPayPayLink()
						} else {
							return
						}
					}
				} else if (state.gift?.type === 2) {
					const check = confirm('ドットマネーを経由して交換になります。')
					if (check) {
						return await getDmoneyLink()
					} else {
						return
					}
				}

				axios.defaults.headers.common['Authorization'] = 'Bearer ' + store.state.deviceToken
				const data = new URLSearchParams()
				data.append('gift_id', String(route.params.id))
				data.append('price', price.toString())
				const check = confirm('ポイントを交換しますか？')
				if (!check) {
					return
				}
				const res = await axios.post('/api2/gift_code/exchange', data)
				location.reload()
			} catch (error) {
				if (axios.isAxiosError(error)) {
					if (error.response) {
						state.error_message = error.response.data.message
					}
				}
			}
		}

		// PayPay連携のリンクを取得
		const getPayPayLink = async () => {
			try {
				axios.defaults.headers.common['Authorization'] = 'Bearer ' + store.state.deviceToken
				const res = await axios.post('/api2/auth/create_paypay_login_url')
				// const width = 500
				// const height = 600
				// const top = window.innerHeight / 2
				// const left = window.innerWidth / 2
				// const x = left - width / 2
				// const y = top - height / 2
				// window.open(res.data.url, 'PayPayポイント', 'top=' + y + ',left=' + x + ',width=500,height=600')
				// window.open(res.data.url)
				location.href = res.data.url
			} catch (error) {
				if (axios.isAxiosError(error)) {
					alert(error.response?.data.message)
				}
			}
		}

		// ドットマネー連携のリンクを取得
		const getDmoneyLink = async () => {
			try {
				axios.defaults.headers.common['Authorization'] = 'Bearer ' + store.state.deviceToken
				const data = new URLSearchParams()
				data.append('withdrawal_product_type', state.gift?.withdrawal_product_type + '')
				data.append('withdrawal_product_id', state.gift?.withdrawal_product_id + '')
				const res = await axios.post('/api2/auth/create_d_money_exchange_url', data)
				// const width = 500
				// const height = 600
				// const top = window.innerHeight / 2
				// const left = window.innerWidth / 2
				// const x = left - width / 2
				// const y = top - height / 2
				// window.open(res.data.url, 'PayPayポイント', 'top=' + y + ',left=' + x + ',width=500,height=600')
				// window.open(res.data.url)
				location.href = res.data.url
			} catch (error) {
				if (axios.isAxiosError(error)) {
					alert(error.response?.data.message)
				}
			}
		}

		const { meta } = useMeta({
			title: 'Apple Gift Cardをお得にポイント交換',
			description: 'ポイ活アプリのポモチでは「ポモチポイント」を貯めることでお得にApple Gift Cardとポイント交換できます。ポモチポイントは広告利用や広告視聴で貯まります。',
		})

		watchEffect(() => {
			meta.title = `${state.gift?.name}でポイントを貯める`
			meta.description = `ポモチでは広告を利用することでポモチポイントが貯まります。${state.gift?.name}の広告をご紹介します。`
		})

		return {
			state,
			unlinkPayPay,
			exchangePoint,
			getPayPayLink,
			getDmoneyLink,
		}
	},
})
