
import { computed, defineComponent, reactive, ref, watchEffect } from 'vue'
import axios from 'axios'
import { useStore } from '../../store'
import { useRoute, useRouter } from 'vue-router'
import { useMeta } from 'vue-meta'
import BreadCrumb from '../../components/BreadCrumb.vue'
import VPagination from '@hennge/vue3-pagination'
import '@hennge/vue3-pagination/dist/vue3-pagination.css'
import getDeviceToken from '@/functions/getDeviceToken'

export default defineComponent({
	components: {
		BreadCrumb,
		VPagination,
	},
	setup() {
		// store
		const store = useStore()

		// route
		const route = useRoute()

		// router
		const router = useRouter()

		// state
		const state = reactive({
			feature: { title: '', description: '' },
			affiliateCategoryTags: [],
			affiliates: [],
			affiliateCategoryTitle: '',
			affiliateCategoryDescription: '',
			affiliatesRecommended: [],
			filteredAffiliateCategoryTags: [],
			isVisibleSearch: true,
			pageNumber: 1,
		})

		// （テスト用）特定のユーザーじゃなければトップへリダイレクト
		const getProfileAndBackHome = async () => {
			try {
				axios.defaults.headers.common['Authorization'] = 'Bearer ' + store.state.deviceToken
				const res = await axios.get('/api2/user/profile')
				if (res.data.user.id !== 75944 && res.data.user.id !== 979 && res.data.user.id !== 16216) {
					return router.push({ name: 'Top' })
				}
			} catch (error: any) {
				alert(error.response.data.message)
			}
		}
		// // getProfileAndBackHome()

		const slicedFeatureAffiliateItems = computed(() => state.affiliates.slice((state.pageNumber - 1) * 10, (state.pageNumber - 1) * 10 + 10))

		const paging = () => {
			// 更新後最上部までスクロール
			return window.scrollTo(0, 0)
		}

		const getFeatureAffiliateItem = async () => {
			try {
				axios.defaults.headers.common['Authorization'] = 'Bearer ' + store.state.deviceToken
				const res = await axios.get('/api2/affiliate_item/index', {
					params: {
						feature_id: route.params.id,
					},
				})
				state.affiliateCategoryTags = res.data.affiliate_category_tags
				state.affiliates = res.data.affiliates
			} catch (error) {
				if (axios.isAxiosError(error)) {
					if (error.response) {
						alert(error.response.data.message)
					}
				}
			}
		}

		const getFeature = async () => {
			try {
				axios.defaults.headers.common['Authorization'] = 'Bearer ' + store.state.deviceToken
				const res = await axios.get(`/api2/feature/detail/${route.params.id}`)

				state.feature = res.data.feature
			} catch (error) {
				if (axios.isAxiosError(error)) {
					if (error.response) {
						alert(error.response.data.message)
					}
				}
			}
		}

		// デバイストークン取得後にその他API起動
		const { deviceToken } = getDeviceToken()
		const start = async () => {
			await deviceToken()
			await getFeature()
			await getFeatureAffiliateItem()
		}
		start()

		// メタ情報
		const { meta } = useMeta({
			title: '',
			description: '',
		})

		watchEffect(() => {
			meta.title = `${state.feature.title}`
			meta.description = `${state.feature.description}`
		})

		return {
			state,
			route,
			router,
			slicedFeatureAffiliateItems,
			paging,
		}
	},
})
