import { toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_metainfo = _resolveComponent("metainfo")!
  const _component_Header = _resolveComponent("Header")!
  const _component_router_view = _resolveComponent("router-view")!
  const _component_DownloadApp = _resolveComponent("DownloadApp")!
  const _component_Footer = _resolveComponent("Footer")!

  return (_openBlock(), _createElementBlock("div", null, [
    _createVNode(_component_metainfo, null, {
      title: _withCtx(({ content }) => [
        _createTextVNode(_toDisplayString(_ctx.route.path === '/lp/value_app' ? content : _ctx.route.path === '/' ? content : content ? `${content} | ポイ活アプリ「ポモチ」` : `ポモチ`), 1)
      ]),
      _: 1
    }),
    _createVNode(_component_Header),
    _createVNode(_component_router_view),
    _createVNode(_component_DownloadApp),
    _createVNode(_component_Footer)
  ]))
}