
import { defineComponent } from 'vue'
import { useMeta } from 'vue-meta'
import BreadCrumb from '../components/BreadCrumb.vue'

export default defineComponent({
	components: {
		BreadCrumb,
	},
	setup() {
		useMeta({
			title: 'お探しのページが見つかりません',
			description: 'ポモチにお探しのページが見つかりませんでした。トップページからお探しください。',
		})
	},
})
