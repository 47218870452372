
import { defineComponent, reactive } from 'vue'
import axios from 'axios'
import { useMeta } from 'vue-meta'
import { useStore } from '../../store'
import { useRouter } from 'vue-router'

import UsersSidebar from '../../components/UsersSidebar.vue'
import BreadCrumb from '../../components/BreadCrumb.vue'
import getDeviceToken from '@/functions/getDeviceToken'

interface CustomFormData extends URLSearchParams {
	append(name: 'values', value: string | string[] | undefined | Blob, fileName?: string): void
}

export default defineComponent({
	components: {
		UsersSidebar,
		BreadCrumb,
	},
	setup() {
		useMeta({
			title: 'マイページ お気に入り店舗',
			description: 'ポモチお気に入り店舗一覧。',
		})

		// vuex
		const store = useStore()

		// router
		const router = useRouter()

		// ログインしていないならトップに遷移
		if (store.state.isLoggedIn === false) {
			router.push({ name: 'Top' })
		}

		// デバイストークン取得後にAPI起動
		const { deviceToken } = getDeviceToken()
		const start = async () => {
			await deviceToken()
			await getFavorite()
		}
		start()

		const branch: {
			data: Array<string | number>
		} = reactive({
			data: [],
		})

		const getFavorite = async () => {
			try {
				axios.defaults.headers.common['Authorization'] = 'Bearer ' + store.state.deviceToken
				const res = await axios.get('/api2/branch/index', {
					params: {
						latitude: 35.681245,
						longitude: 139.765848,
						is_current_place: false,
						is_favorite: true,
					},
				})

				branch.data = res.data.branches
			} catch (error: any) {
				alert(error.response.data.message)
			}
		}
		return {
			branch,
		}
	},
})
