
import { computed, defineComponent, reactive } from 'vue'
import axios from 'axios'
import { useMeta } from 'vue-meta'
import { useStore } from '../../store'
import { useRouter } from 'vue-router'

import UsersSidebar from '../../components/UsersSidebar.vue'
import BreadCrumb from '../../components/BreadCrumb.vue'
import getDeviceToken from '@/functions/getDeviceToken'

interface CustomURLSearchParams extends URLSearchParams {
	append(name: 'values', value: string | string[] | undefined | Blob, fileName?: string): void
}

export default defineComponent({
	components: {
		UsersSidebar,
		BreadCrumb,
	},
	setup() {
		useMeta({
			title: '利用している決済サービスの情報',
			description: '自分の利用している決済サービスを入力すると店舗詳細画面で自分の使っている決済サービスの中で一番還元率の高い支払い方法がわかります。',
		})

		// vuex
		const store = useStore()

		// router
		const router = useRouter()

		// ログインしていないならトップに遷移
		if (store.state.isLoggedIn === false) {
			router.push({ name: 'Top' })
		}

		// デバイストークン取得後にAPI起動
		const { deviceToken } = getDeviceToken()
		const start = async () => {
			await deviceToken()
			await getPays()
		}
		start()

		// 決済サービス情報格納用オブジェクト
		const pays: {
			haved: boolean
			info: Array<string | number>
			type: Array<string>
		} = reactive({
			haved: false,
			info: [],
			type: [],
		})

		// 利用している決済サービス情報取得
		const getPays = async () => {
			try {
				axios.defaults.headers.common['Authorization'] = 'Bearer ' + store.state.deviceToken
				const res = await axios.get('/api2/user_information/get_pays')

				pays.info = res.data.options
				pays.type = res.data.values
			} catch (error: any) {
				alert(error.response.data.message)
			}
		}

		// 利用している決済サービス更新判定用
		const updateDone = reactive({
			tof: false,
		})

		// 利用している決済サービス情報更新
		const updatePays = async () => {
			try {
				// 更新判定をtrueに
				updateDone.tof = true

				const data = new URLSearchParams() as CustomURLSearchParams
				data.append('values', pays.type)
				axios.defaults.headers.common['Authorization'] = 'Bearer ' + store.state.deviceToken
				const res = await axios.post('/api2/user_information/update_pays', data)

				// 更新後最上部までスクロール
				window.scrollTo(0, 0)
			} catch (error: any) {
				alert(error.response.data.message)
			}
		}

		// 使用しているPayでフィルターをかける
		const filterPays = computed(() => {
			if (pays.haved) {
				const filtered = pays.info.filter((pay: any) => {
					return pays.type.includes(pay.value)
				})
				return filtered
			}
			return pays.info
		})

		return {
			pays,
			updatePays,
			updateDone,
			filterPays,
		}
	},
})
