
import { defineComponent } from 'vue'
import axios from 'axios'
import { reactive } from 'vue'
import { useStore } from '../../store'
import { useRouter } from 'vue-router'
import { useMeta } from 'vue-meta'

import UsersSidebar from '../../components/UsersSidebar.vue'
import BreadCrumb from '../../components/BreadCrumb.vue'
import getDeviceToken from '@/functions/getDeviceToken'

export default defineComponent({
	components: {
		UsersSidebar,
		BreadCrumb,
	},
	setup() {
		useMeta({
			title: '利用している決済サービスのステータス',
			description: '決済サービス・ポイントプログラムのステータスを入力すると店舗詳細ページで自分のポイント還元率が反映されます。',
		})

		// vuex
		const store = useStore()

		// router
		const router = useRouter()

		// ログインしていないならトップに遷移
		if (store.state.isLoggedIn === false) {
			router.push({ name: 'Top' })
		}

		// デバイストークン取得後にAPI起動
		const { deviceToken } = getDeviceToken()
		const start = async () => {
			await deviceToken()
			await getPayStatus()
		}
		start()

		// payStatus作成
		const payStatus: {
			[key: string]: any
		} = reactive({})

		// 決済サービスのステータス取得
		const getPayStatus = async () => {
			try {
				axios.defaults.headers.common['Authorization'] = 'Bearer ' + store.state.deviceToken
				const res = await axios.get('/api2/user/pay_status')
				res.data.pay_status.forEach((paystatus: any) => {
					payStatus[paystatus.code] = paystatus
				})
			} catch (error: any) {
				alert(error.response.data.message)
			}
		}

		// 決済サービスのステータス更新判定用
		const updateDone = reactive({
			tof: false,
		})

		// 決済サービスのステータス更新
		const updatePayStatus = async () => {
			try {
				// 更新判定をtrueに
				updateDone.tof = true

				const data = new URLSearchParams()
				let key: string
				for (key in payStatus) {
					data.append(key, payStatus[key].value)
				}
				axios.defaults.headers.common['Authorization'] = 'Bearer ' + store.state.deviceToken
				const res = await axios.post('/api2/user/update_profile', data)
				window.scrollTo(0, 0)
			} catch (error: any) {
				alert(error.response.data.message)
			}
		}
		return {
			payStatus,
			updatePayStatus,
			updateDone,
		}
	},
})
