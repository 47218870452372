import { createRouter, createWebHistory, RouteRecordRaw } from 'vue-router'
import Top from '@/views/top/Top.vue'
import BranchIndex from '@/views/branch/Index.vue'
import BranchShow from '@/views/branch/Show.vue'
import NoticeIndex from '@/views/notice/Index.vue'
import NoticeDetail from '@/views/notice/Detail.vue'
import Signup from '@/views/auth/Signup.vue'
// import SignupDone from '@/views/auth/SignupDone.vue'
import Login from '@/views/auth/Login.vue'
import Logout from '@/views/auth/Logout.vue'
import Withdraw from '@/views/auth/Withdraw.vue'
import Profile from '@/views/user/Profile.vue'
import PayStatus from '@/views/user/PayStatus.vue'
import ContactAdd from '@/views/contact/Add.vue'
import ContributionAdd from '@/views/contribution/Add.vue'
import ContributionAnswer from '@/views/contribution/Answer.vue'
import UserInformationIndex from '@/views/user_information/Index.vue'
import UserInformationCards from '@/views/user_information/Cards.vue'
import UserInformationPays from '@/views/user_information/Pays.vue'
import UserCertification from '@/views/user/Certification.vue'
import UserPointLog from '@/views/user/PointLog.vue'
import UserGiftLog from '@/views/user/GiftLog.vue'
import UserInvitationLog from '@/views/user/InvitationLog.vue'
import UserInvitationInfo from '@/views/user/InvitationInfo.vue'
import UserNotificationMail from '@/views/user/NotificationMail.vue'
// import Manga from '@/views/plan/Manga.vue'
import FavoriteList from '@/views/branch/FavoriteList.vue'
import AffiliateItemDetail from '@/views/affiliate_item/Detail.vue'
import AffiliateItemIndex from '@/views/affiliate_item/Index.vue'
import AffiliateItemFeature from '@/views/affiliate_item/Feature.vue'
import GiftIndex from '@/views/gift/Index.vue'
import GiftDigico from '@/views/gift/Digico.vue'
import GiftAmazonGift from '@/views/gift/AmazonGift.vue'
import GiftPaypay from '@/views/gift/Paypay.vue'
import GiftAppleGiftCard from '@/views/gift/AppleGiftCard.vue'
import GiftVpreca from '@/views/gift/Vpreca.vue'
import GiftId from '@/views/gift/Id.vue'
import InvitationAbout from '@/views/invitation/About.vue'
import InvitationRanking from '@/views/invitation/Ranking.vue'
import LpValueApp from '@/views/lp/ValueApp.vue'
import LpGame from '@/views/lp/Game.vue'
import NotFound from '@/views/NotFound.vue'

// import AffiliateItemPayPayPoint from '@/views/affiliate_item/paypay_point.vue'
// import AffiliateItemShopping from '@/views/affiliate_item/shopping.vue'
// import AffiliateItemCredit from '@/views/affiliate_item/credit.vue'
// import AffiliateItemAdvertise from '@/views/affiliate_item/advertise.vue'
import AffiliateItemAmazonPoint from '@/views/affiliate_item/amazon_point.vue'
import AffiliateItemPointExchange from '@/views/affiliate_item/point_exchange.vue'
import AffiliateItemFriend from '@/views/affiliate_item/friend.vue'

const routes: Array<RouteRecordRaw> = [
	{
		path: '/',
		component: Top,
		name: 'Top',
	},
	{
		path: '/branch',
		component: BranchIndex,
		name: 'BranchIndex',
	},
	{
		path: '/branch/show/:id',
		component: BranchShow,
		name: 'BranchShow',
	},
	{
		path: '/auth/signup',
		component: Signup,
		name: 'Signup',
	},
	// {
	// 	path: '/auth/signup_done',
	// 	component: SignupDone,
	// 	name: 'SignupDone',
	// },
	{
		path: '/auth/login',
		component: Login,
		name: 'Login',
	},
	{
		path: '/auth/logout',
		component: Logout,
		name: 'Logout',
	},
	{
		path: '/auth/withdraw',
		component: Withdraw,
		name: 'Withdraw',
	},
	{
		path: '/notice',
		component: NoticeIndex,
		name: 'NoticeIndex',
	},
	{
		path: '/notice/detail/:id',
		component: NoticeDetail,
		name: 'NoticeDetail',
	},
	{
		path: '/user/profile',
		component: Profile,
		name: 'Profile',
	},
	{
		path: '/user/certification',
		component: UserCertification,
		name: 'UserCertification',
	},
	{
		path: '/user/point_log',
		component: UserPointLog,
		name: 'UserPointLog',
	},
	{
		path: '/user/gift_log',
		component: UserGiftLog,
		name: 'UserGiftLog',
	},
	{
		path: '/user/invitation_log',
		component: UserInvitationLog,
		name: 'UserInvitationLog',
	},
	{
		path: '/user/invitation_info',
		component: UserInvitationInfo,
		name: 'UserInvitationInfo',
	},
	{
		path: '/user/pay_status',
		component: PayStatus,
		name: 'PayStatus',
	},
	{
		path: '/user/notification_mail',
		component: UserNotificationMail,
		name: 'UserNotificationMail',
	},
	{
		path: '/contact/add',
		component: ContactAdd,
		name: 'ContactAdd',
	},
	{
		path: '/contribution/add/:id',
		component: ContributionAdd,
		name: 'ContributionAdd',
	},
	{
		path: '/contribution/answer/:id',
		component: ContributionAnswer,
		name: 'ContributionAnswer',
	},
	{
		path: '/user_information',
		component: UserInformationIndex,
		name: 'UserInformationIndex',
	},
	{
		path: '/user_information/cards',
		component: UserInformationCards,
		name: 'UserInformationCards',
	},
	{
		path: '/user_information/pays',
		component: UserInformationPays,
		name: 'UserInformationPays',
	},
	// {
	// 	path: '/plan/manga',
	// 	component: Manga,
	// 	name: 'Manga',
	// },
	{
		path: '/branch/favorite_list',
		component: FavoriteList,
		name: 'FavoriteList',
	},
	{
		path: '/affiliate_item/detail/:id',
		component: AffiliateItemDetail,
		name: 'AffiliateItemDetail',
	},
	{
		path: '/affiliate_item/:page',
		component: AffiliateItemIndex,
		name: 'AffiliateItemIndex',
	},
	{
		path: '/affiliate_item/feature/:id',
		component: AffiliateItemFeature,
		name: 'AffiliateItemFeature',
	},
	{
		path: '/gift/',
		component: GiftIndex,
		name: 'GiftIndex',
	},
	// {
	// 	path: '/gift/digico',
	// 	component: GiftDigico,
	// 	name: 'GiftDigico',
	// },
	// {
	// 	path: '/gift/amazon_gift',
	// 	component: GiftAmazonGift,
	// 	name: 'GiftAmazonGift',
	// },
	// {
	// 	path: '/gift/paypay',
	// 	component: GiftPaypay,
	// 	name: 'GiftPaypay',
	// },
	// {
	// 	path: '/gift/apple_gift_card',
	// 	component: GiftAppleGiftCard,
	// 	name: 'GiftAppleGiftCard',
	// },
	// {
	// 	path: '/gift/vpreca',
	// 	component: GiftVpreca,
	// 	name: 'GiftVpreca',
	// },
	// {
	// 	path: '/gift/d_money',
	// 	component: GiftDmoney,
	// 	name: 'GiftDmoney',
	// },
	{
		path: '/gift/:id',
		component: GiftId,
		name: 'GiftId',
	},
	{
		path: '/invitation/about',
		component: InvitationAbout,
		name: 'InvitationAbout',
	},
	{
		path: '/invitation/ranking',
		component: InvitationRanking,
		name: 'InvitationRanking',
	},
	{
		path: '/lp/value_app',
		component: LpValueApp,
		name: 'LpValueApp',
	},
	{
		path: '/lp/game',
		component: LpGame,
		name: 'LpGame',
	},
	{
		path: '/:pathMatch(.*)*',
		component: NotFound,
		name: 'NotFound',
	},
]

const router = createRouter({
	history: createWebHistory(process.env.BASE_URL),
	routes,
	scrollBehavior(to, from, savedPosition) {
		return { left: 0, top: 0 }
	},
})

export default router
