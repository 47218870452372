
import { defineComponent, reactive, ref, watch, watchEffect, onMounted } from 'vue'
import axios from 'axios'
import { useRoute, useRouter } from 'vue-router'
import { useStore } from '@/store'
import { useMeta } from 'vue-meta'
import getDeviceToken from '../../functions/getDeviceToken'

import BreadCrumb from '../../components/BreadCrumb.vue'

export default defineComponent({
	components: {
		BreadCrumb,
	},
	setup() {
		// useMeta({
		// 	title: `（ニュースtitle）-新着情報`,
		// 	description: 'ポモチの新着情報「（ニュースtitle）」です。参考にしてお得な日々を過ごしてください。',
		// })

		// route
		const route = useRoute()

		// router
		const router = useRouter()

		// store
		const store = useStore()

		// お知らせ格納用オブジェクト
		const notices = reactive({
			data: [],
		})

		// お知らせ詳細用オブジェクト
		const noticeDetail = reactive({
			title: '',
			description: '',
			type: '',
			url: '',
		})

		// お知らせ取得
		const getNotices = async () => {
			try {
				// お知らせ詳細取得
				axios.defaults.headers.common['Authorization'] = 'Bearer ' + store.state.deviceToken
				const resNoticeDetail = await axios.get(`/api2/notice/detail/${route.params.id}`)
				noticeDetail.title = resNoticeDetail.data.notice.title
				noticeDetail.description = resNoticeDetail.data.notice.description.replace(/<br>/g, '').replace(/<br\/>/g, '')
				noticeDetail.type = resNoticeDetail.data.notice.type
				noticeDetail.url = resNoticeDetail.data.notice.web_url

				// 関連するお知らせ取得
				axios.defaults.headers.common['Authorization'] = 'Bearer ' + store.state.deviceToken
				const resRelatedNotice = await axios.get(`/api2/notice/index?type=${noticeDetail.type}&page=1`)

				// 閲覧しているお知らせ以外のお知らせを取得
				notices.data = resRelatedNotice.data.notices
					.filter((notice: any) => notice.id !== route.params.id)
					.map((notice: any) => ({
						id: notice.id,
						title: notice.title,
						description: notice.description,
						url: notice.url,
						showed_at: notice.showed_at.slice(0, 11),
					}))
			} catch (error) {
				router.push({ name: 'NotFound' })
			}
		}

		// デバイストークン取得後にAPI起動
		const { deviceToken } = getDeviceToken()
		const start = async () => {
			await deviceToken()
			await getNotices()
		}
		start()

		// 別のお知らせがクリックされたら、再度APIを叩く関数
		watch(route, () => {
			if (route.path.startsWith('/notice/detail/')) {
				getNotices()
			}
		})

		// メタ情報
		const { meta } = useMeta({
			title: '',
			description: '',
			bodyAttrs: {
				class: '',
			},
		})

		watchEffect(() => {
			meta.title = `${noticeDetail.title}-新着情報`
			meta.description = `ポモチの新着情報「${noticeDetail.title}」です。参考にしてお得な日々を過ごしてください。`
		})

		// adsense
		// ➊ divコンテナーの参照
		const containerRef = ref<HTMLDivElement>()

		// ➋ コンポーネントがマウントされたときの処理
		onMounted(() => {
			// スクリプトを定義 (AdSense本体をロード)
			const adsScript = document.createElement(`script`)
			adsScript.async = true
			adsScript.crossOrigin = `anonymous`
			adsScript.src = `https://pagead2.googlesyndication.com/pagead/js/adsbygoogle.js?client=ca-pub-7417509164255203`

			// スクリプトを定義 (AdSense実行)
			const inlineScript = document.createElement(`script`)
			inlineScript.type = `text/javascript`
			inlineScript.text = `(adsbygoogle = window.adsbygoogle || []).push({});`

			// DOMに追加
			containerRef.value?.appendChild(adsScript)
			containerRef.value?.appendChild(inlineScript)
		})

		return {
			notices,
			noticeDetail,
		}
	},
})
