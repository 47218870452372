
import { defineComponent } from 'vue'
import axios from 'axios'
import { reactive } from 'vue'
import { useMeta } from 'vue-meta'
import { useStore } from '../../store'
import UsersSidebar from '../../components/UsersSidebar.vue'
import BreadCrumb from '../../components/BreadCrumb.vue'
import VFacebookLogin from 'vue-facebook-login-component-next'
import { useRouter } from 'vue-router'
import getDeviceToken from '@/functions/getDeviceToken'

export default defineComponent({
	components: {
		UsersSidebar,
		VFacebookLogin,
		BreadCrumb,
	},
	setup() {
		useMeta({
			title: 'ログアウト画面',
			description: 'ポモチからログアウトしますか？',
		})

		// router
		const router = useRouter()

		// テスト完了までトップに戻す
		// router.push({ name: 'Top' })

		// vuex
		const store = useStore()

		// ログインしていないならトップに遷移
		if (store.state.isLoggedIn === false) {
			router.push({ name: 'Top' })
		}

		// デバイストークン取得
		const { deviceToken } = getDeviceToken()
		deviceToken()

		// 完了判定用
		const logoutDone = reactive({
			tof: false,
		})

		// facebookSDK取得
		const fb = reactive({
			FB: {},
			scope: {},
		})
		const handleSdkInit = ({ FB, scope }: any) => {
			fb.FB = FB
			fb.scope = scope
		}

		// facebookのAppIDを本番とテストで分ける
		let fbAppId: string | undefined = ''
		const checkEnvFbAppId = () => {
			const hostname = location.hostname
			if (hostname === 'www.ai-credit.com') {
				fbAppId = process.env.VUE_APP_FACEBOOK_APP_ID
			} else {
				fbAppId = process.env.VUE_APP_FACEBOOK_APP_ID_DEV
			}
		}
		checkEnvFbAppId()

		// ログアウト
		const logout = async () => {
			try {
				// 完了判定をtrueに
				logoutDone.tof = true

				// ユーザーのログイン状態を未ログインに
				store.dispatch('changeLoginUserState')

				axios.defaults.headers.common['Authorization'] = 'Bearer ' + store.state.deviceToken
				const res = await axios.post('/api2/auth/logout')

				// facebookログアウト
				;(fb.FB as any).logout(function (response: any) {
					// facebookトークン削除
					store.dispatch('deleteFBToken')
				})

				// ユーザーのログイン状態を未ログインに
				store.dispatch('changeLoginUserState')

				// ログイン画面にリダイレクト
				router.push({ name: 'Login' })
			} catch (error: any) {
				alert(error.response.data.message)
			}
		}

		return {
			logoutDone,
			logout,
			handleSdkInit,
			fbAppId,
		}
	},
})
