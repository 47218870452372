
import { defineComponent, reactive, watch } from 'vue'
import axios from 'axios'
import { useMeta } from 'vue-meta'

import UsersSidebar from '../../components/UsersSidebar.vue'
import BreadCrumb from '../../components/BreadCrumb.vue'
import { useRouter } from 'vue-router'
import { useStore } from '@/store'
import getDeviceToken from '@/functions/getDeviceToken'

// 型定義
interface State {
	name?: string
	mail_address: string
	type: string[] | string
	description: string
}
interface CustomURLSearchParams extends URLSearchParams {
	append(name: keyof State, value: string | string[] | undefined | Blob, fileName?: string): void
}

export default defineComponent({
	components: {
		UsersSidebar,
		BreadCrumb,
	},
	setup() {
		useMeta({
			title: '問い合わせページ',
			description: 'ポモチのお問い合わせはこちらから。',
		})

		// router
		const router = useRouter()

		// store
		const store = useStore()

		// ログインしていないならトップに遷移
		if (store.state.isLoggedIn === false) {
			router.push({ name: 'Top' })
		}

		// デバイストークン取得後にAPI起動
		const { deviceToken } = getDeviceToken()
		const start = async () => {
			await deviceToken()
			await getProfile()
		}
		start()
		// テスト完了までトップに戻す
		// router.push({ name: 'Top' })

		// state作成
		const state = reactive<State>({
			name: '',
			mail_address: '',
			type: [],
			description: '',
		})

		// チェックボックス作成
		const checkboxes = reactive({
			checkbox1: 'サービスの不具合・要望',
			checkbox2: '広告掲載',
			checkbox3: '「最新クレジットカード完全ガイド」でのプレスリリース・ニュース配信',
			checkbox4: '寄稿',
			checkbox5: 'その他',
		})

		// 入力判定用
		const inputDone = reactive({
			confirmTof: false,
			sendTof: false,
			sendBtnTof: false,
		})

		// 入力内容確認画面へ
		const confirmForm = () => {
			inputDone.confirmTof = true
			// 更新後最上部までスクロール
			window.scrollTo(0, 0)
		}

		watch(
			() => state.type,
			() => {
				if (state.type.length === 0) {
					const requireCheckBoxes = document.querySelectorAll('.checkbox-input')
					requireCheckBoxes.forEach((requireCheckBox) => {
						requireCheckBox.setAttribute('required', 'required')
					})
				} else {
					const requireCheckBoxes = document.querySelectorAll('.checkbox-input')
					requireCheckBoxes.forEach((requireCheckBox) => {
						requireCheckBox.removeAttribute('required')
					})
				}
			},
		)

		// プロフィール取得
		const getProfile = async () => {
			try {
				axios.defaults.headers.common['Authorization'] = 'Bearer ' + store.state.deviceToken
				const res = await axios.get('/api2/user/profile')
				// 取得したデータをstateに代入
				state.name = res.data.user.name
				state.mail_address = res.data.user.mail_address
			} catch (error: any) {
				alert(error.response.data.message)
			}
		}

		// 問い合わせ送信
		const sendForm = async () => {
			try {
				// 入力判定をtrueに
				inputDone.sendTof = true
				inputDone.sendBtnTof = true

				const data = new URLSearchParams() as CustomURLSearchParams
				data.append('name', state.name)
				data.append('mail_address', state.mail_address)
				data.append('type', state.type)
				data.append('description', state.description)

				axios.defaults.headers.common['Authorization'] = 'Bearer ' + store.state.deviceToken
				const res = await axios.post('/api2/contact/add', data)

				// 更新後最上部までスクロール
				window.scrollTo(0, 0)
			} catch (error: any) {
				alert(error.response.data.message)
			}
		}

		return {
			state,
			inputDone,
			checkboxes,
			confirmForm,
			sendForm,
		}
	},
})
