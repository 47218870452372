
import { defineComponent, ref } from 'vue'
import axios from 'axios'
import { useStore } from '../store'

export default defineComponent({
	name: 'Header',
	setup() {
		const store = useStore()

		const point = ref<number | null>(null)

		// プロフィール取得
		const getProfile = async () => {
			axios.defaults.headers.common['Authorization'] = 'Bearer ' + store.state.deviceToken
			const res = await axios.get('/api2/user/profile')
			point.value = res.data.user.point
		}
		if (store.state.isLoggedIn) {
			getProfile()
		}

		const isVisible = ref(false)
		return {
			store,
			point,
			isVisible,
		}
	},
})
