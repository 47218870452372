
import { useStore } from '@/store'
import axios from 'axios'
import { defineComponent, ref } from 'vue'
import { useRoute } from 'vue-router'

export default defineComponent({
	name: 'UsersSidebar',
	setup() {
		const route = useRoute()

		// store
		const store = useStore()

		const canInvite = ref<boolean | null>(null)

		const isVisiblePointMenu = ref(false)

		const isVisibleInvitationMenu = ref(false)

		// プロフィール取得
		const getProfile = async () => {
			try {
				axios.defaults.headers.common['Authorization'] = 'Bearer ' + store.state.deviceToken
				const res = await axios.get('/api2/user/profile')

				// 紹介コードを入力の出現判定用
				canInvite.value = res.data.user.can_invite
			} catch (error: any) {
				return alert(error.response.data.message)
			}
		}
		getProfile()
		return {
			route,
			canInvite,
			isVisiblePointMenu,
			isVisibleInvitationMenu,
		}
	},
})
