
import { defineComponent } from 'vue'
import axios from 'axios'
import { reactive } from 'vue'
import { useStore } from '../../store'
import { useMeta } from 'vue-meta'

import UsersSidebar from '../../components/UsersSidebar.vue'
import BreadCrumb from '../../components/BreadCrumb.vue'
import VFacebookLogin from 'vue-facebook-login-component-next'
import { useRouter } from 'vue-router'
import getDeviceToken from '@/functions/getDeviceToken'

export default defineComponent({
	components: {
		UsersSidebar,
		VFacebookLogin,
		BreadCrumb,
	},
	setup() {
		useMeta({
			title: '退会画面',
			description: 'ポモチの退会はこちらから行えます。',
		})

		// vuex
		const store = useStore()

		// router
		const router = useRouter()

		// ログインしていないならトップに遷移
		if (store.state.isLoggedIn === false) {
			router.push({ name: 'Top' })
		}

		// デバイストークン取得
		const { deviceToken } = getDeviceToken()
		deviceToken()
		// テスト完了までトップに戻す
		// router.push({ name: 'Top' })

		// 完了判定用
		const withdrawDone = reactive({
			tof: false,
		})

		// facebookSDK取得
		const fb = reactive({
			FB: {},
			scope: {},
		})
		const handleSdkInit = ({ FB, scope }: any) => {
			fb.FB = FB
			fb.scope = scope
		}

		// facebookのAppIDを本番とテストで分ける
		let fbAppId: string | undefined = ''
		const checkEnvFbAppId = () => {
			const hostname = location.hostname
			if (hostname === 'www.ai-credit.com') {
				fbAppId = process.env.VUE_APP_FACEBOOK_APP_ID
			} else {
				fbAppId = process.env.VUE_APP_FACEBOOK_APP_ID_DEV
			}
		}
		checkEnvFbAppId()

		// 退会
		const withdraw = async () => {
			try {
				const result = confirm('退会するとデータが全て削除されますが、本当に退会してよろしいですか？')

				if (result) {
					// 完了判定をtrueに
					withdrawDone.tof = true

					axios.defaults.headers.common['Authorization'] = 'Bearer ' + store.state.deviceToken
					const res = await axios.post('/api2/auth/withdraw')

					// facebookログアウト
					;(fb.FB as any).logout(function (response: any) {
						// facebookトークン削除
						store.dispatch('deleteFBToken')
					})

					// デバイストークン削除
					store.dispatch('deleteDeviceToken')

					// ユーザーのログイン状態を未ログインに
					store.dispatch('changeLoginUserState')

					// 1.5秒後にトップページへ遷移
					setTimeout(() => {
						router.push({ name: 'Top' })
					}, 1500)

					return
				} else {
					return
				}
			} catch (error: any) {
				alert(error.response.data.message)
			}
		}
		return {
			withdrawDone,
			withdraw,
			handleSdkInit,
			fbAppId,
		}
	},
})
