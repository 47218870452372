
import { defineComponent, ref, watch } from 'vue'
import axios from 'axios'
import { reactive } from 'vue'
import { useStore } from '../../store'
import { useRouter } from 'vue-router'
import useVuelidate from '@vuelidate/core'
import { required, email, minLength } from '@vuelidate/validators'
import { useMeta } from 'vue-meta'
import BreadCrumb from '../../components/BreadCrumb.vue'
import VFacebookLogin from 'vue-facebook-login-component-next'
import getDeviceToken from '../../functions/getDeviceToken'

import { Swiper, SwiperSlide } from 'swiper/vue'
import 'swiper/swiper-bundle.min.css'
import 'swiper/swiper.min.css'
import 'swiper/components/navigation/navigation.min.css'
import 'swiper/components/pagination/pagination.min.css'
import SwiperCore, { Autoplay, Pagination, Navigation, Thumbs } from 'swiper/core'
SwiperCore.use([Autoplay, Pagination, Navigation, Thumbs])

export default defineComponent({
	components: {
		VFacebookLogin,
		BreadCrumb,
		Swiper,
		SwiperSlide,
	},
	setup() {
		useMeta({
			title: 'ログイン画面',
			description: 'ポモチのログイン画面です。ログインはこちらから。',
		})

		// デバイストークン取得
		const { deviceToken } = getDeviceToken()
		deviceToken()

		// vuex
		const store = useStore()

		// router
		const router = useRouter()

		// テスト完了までトップに戻す
		// router.push({ name: 'Top' })
		if (store.state.isLoggedIn) {
			router.push({ name: 'Profile' })
		}

		// state作成
		const state = reactive({
			mail_address: '',
			password: '',
		})

		// バリデーション
		const rules = {
			mail_address: { required, email },
			password: { required, min: minLength(8) },
		}
		const v$ = useVuelidate(rules, state)

		// スライダー
		const bannerSwiper: any = ref(null)
		const setBannerSwiper = (swiper: any) => {
			bannerSwiper.value = swiper
		}

		// ログイン
		const login = async () => {
			try {
				const form = new FormData()
				form.append('mail_address', state.mail_address)
				form.append('password', state.password)
				axios.defaults.headers.common['Authorization'] = 'Bearer ' + store.state.deviceToken
				const res = await axios.post('/api2/auth/login', form)

				// vuexにログイン状態保存
				store.dispatch('getLoginUser')

				// トップに遷移
				return (location.href = `/web/dist/?login_done=1`)
				// return router.push({ name: 'Top', query: { login_done: '1' } })
				// router.push({ name: 'Profile', query: { first: 'true' } })
			} catch (error: any) {
				alert(error.response.data.message)
			}
		}

		// fbオブジェクト
		const fb = reactive({
			FB: {},
			scope: {},
			access_token: '',
		})

		// facebookのAppIDを本番とテストで分ける
		let fbAppId: string | undefined = ''
		const checkEnvFbAppId = () => {
			const hostname = location.hostname
			if (hostname === 'www.ai-credit.com') {
				fbAppId = process.env.VUE_APP_FACEBOOK_APP_ID
			} else {
				fbAppId = process.env.VUE_APP_FACEBOOK_APP_ID_DEV
			}
		}
		checkEnvFbAppId()

		// facebookにログイン
		const facebookLogin = (res: any) => {
			if (res.status === 'connected') {
				store.state.fbToken = res.authResponse.accessToken
				fb.access_token = res.authResponse.accessToken
			}
		}

		// facebookアクセストークンでaicにログイン
		const aicFacebookLogin = async () => {
			try {
				const data = new FormData()
				data.append('fb_access_token', store.state.fbToken)
				axios.defaults.headers.common['Authorization'] = 'Bearer ' + store.state.deviceToken
				const res = await axios.post('/api2/auth/login_facebook', data)
				store.dispatch('getLoginFBUser')
				store.dispatch('getLoginUser')

				// トップに遷移
				return (location.href = `/web/dist/?login_done=1`)
				// router.push({ name: 'Profile', query: { first: 'true' } })
			} catch (error: any) {
				alert(error.response.data.message)
			}
		}

		// facebookアクセストークンがあれば、aicログイン処理
		watch(
			() => fb.access_token,
			() => {
				if (fb.access_token !== '') {
					aicFacebookLogin()
				}
			},
		)

		const apple = reactive({
			signedIn: false,
			code: '',
		})

		const appleOnSuccess = (data: any) => {
			apple.signedIn = true
			apple.code = data.authorization.code
			appleLogin()
		}

		const appleOnSuccessLogout = () => {
			apple.signedIn = false
		}

		const appleOnFailure = (error: any) => {
			location.reload()
		}

		const appleLogin = async () => {
			try {
				const data = new URLSearchParams()
				data.append('authorization_code', apple.code)
				axios.defaults.headers.common['Authorization'] = 'Bearer ' + store.state.deviceToken
				const res = await axios.post('/api2/auth/login_apple', data)
				// vuexにログイン状態保存
				store.dispatch('getLoginUser')
				// トップに遷移
				return (location.href = `/web/dist/?login_done=1`)
			} catch (error: any) {
				alert(error.response.data.message)
			}
		}

		// // facebookアクセストークンがあれば、aicログイン処理
		// watch(
		// 	() => apple.code,
		// 	() => {
		// 		if (apple.code !== '') {
		// 			appleLogin()
		// 		}
		// 	},
		// )

		return {
			state,
			v$,
			login,
			fb,
			facebookLogin,
			fbAppId,
			apple,
			appleOnSuccess,
			appleOnSuccessLogout,
			appleOnFailure,
			setBannerSwiper,
		}
	},
})
