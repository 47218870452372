import { createApp } from 'vue'
import App from './App.vue'
import { createGtm } from 'vue-gtm'
import router from './router'
import { store, key } from '@/store'
import { createMetaManager } from 'vue-meta'
import VueAppleLogin from '@/plugins/vue-apple-login'

createApp(App)
	.use(store, key)
	.use(router)
	.use(createMetaManager())
	.use(VueAppleLogin, {
		clientId: 'jp.lier-factory.ai-credit.login',
		scope: 'email',
		redirectURI: location.origin,
		state: 'STATE',
		usePopup: true,
	})
	.use(
		createGtm({
			id: 'GTM-5GTQ7VX',
			defer: false,
			compatibility: false,
			nonce: '9zeze/6f+M+GSB5N',
			enabled: true,
			debug: true,
			loadScript: true,
			vueRouter: router,
			trackOnNextTick: false,
		}),
	)
	.mount('#app')

// const get_device_token = async () => {
// 	try {
// 		const params = new URLSearchParams({
// 			terminal: "Macbook Pro",
// 			os: "mac",
// 			os_version: "11.4",
// 			app_version: "1.0.0",
// 		});
// 		const res = await axios.post("/api2/device/add", params);
// 		const items = res.data;
// 		store.dispatch("getDeviceToken", {
// 			deviceToken: items.device_token
// 		})
// 		console.log(items);
// 	} catch (error) {
// 		console.log(error.response);
// 	}
// }

// get_device_token()
// console.log(store.state);
// console.log(document.cookie);
