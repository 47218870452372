
import { defineComponent, reactive, ref } from 'vue'
import axios from 'axios'
import useVuelidate from '@vuelidate/core'
import { required, email } from '@vuelidate/validators'
import { useStore } from '../../store'
import { useRouter } from 'vue-router'

import UsersSidebar from '../../components/UsersSidebar.vue'
import BreadCrumb from '../../components/BreadCrumb.vue'
import { useMeta } from 'vue-meta'
import getDeviceToken from '@/functions/getDeviceToken'

// 型定義
type State = {
	name?: string
	mail_address?: string
	birthday?: string
	sex_id?: number
	prefecture_id?: number
	city_id?: number
	work_id?: number
	invitation_code?: string
	can_invite?: boolean
	invited_at: string | null
}
interface CustomURLSearchParams extends URLSearchParams {
	append(name: keyof State, value: string | number | undefined | Blob, fileName?: string): void
}

export default defineComponent({
	components: {
		UsersSidebar,
		BreadCrumb,
	},
	setup() {
		useMeta({
			title: 'マイページ',
			description: 'ポモチのマイページ。お気に入り登録や自分の利用している決済サービスを登録しましょう。',
		})

		// vuex
		const store = useStore()

		// router
		const router = useRouter()

		// ログインしていないならトップに遷移
		if (store.state.isLoggedIn === false) {
			router.push({ name: 'Top' })
		}

		// 初回ログイン判定
		const firstLogin = ref('none')
		if (router.currentRoute.value.query.first !== '') {
			firstLogin.value = router.currentRoute.value.query.first as string
		}

		// 都道府県
		const prefectures = [
			'未指定',
			'北海道',
			'青森県',
			'岩手県',
			'宮城県',
			'秋田県',
			'山形県',
			'福島県',
			'茨城県',
			'栃木県',
			'群馬県',
			'埼玉県',
			'千葉県',
			'東京都',
			'神奈川県',
			'新潟県',
			'富山県',
			'石川県',
			'福井県',
			'山梨県',
			'長野県',
			'岐阜県',
			'静岡県',
			'愛知県',
			'三重県',
			'滋賀県',
			'京都府',
			'大阪府',
			'兵庫県',
			'奈良県',
			'和歌山県',
			'鳥取県',
			'島根県',
			'岡山県',
			'広島県',
			'山口県',
			'徳島県',
			'香川県',
			'愛媛県',
			'高知県',
			'福岡県',
			'佐賀県',
			'長崎県',
			'熊本県',
			'大分県',
			'宮崎県',
			'鹿児島県',
			'沖縄県',
		]

		// 市区町村
		const cities = ref([])

		// state作成
		const state = reactive<State>({
			name: '',
			mail_address: '',
			birthday: '',
			sex_id: undefined,
			prefecture_id: undefined,
			city_id: undefined,
			work_id: undefined,
			invitation_code: undefined,
			can_invite: false,
			invited_at: null,
		})

		// バリデーション
		const rules: any = {
			name: { required },
			mail_address: { required, email },
		}
		const v$ = useVuelidate(rules, state)

		// プロフィール取得
		const getProfile = async () => {
			try {
				axios.defaults.headers.common['Authorization'] = 'Bearer ' + store.state.deviceToken
				const res = await axios.get('/api2/user/profile')

				// 取得したデータをstateに代入
				state.name = res.data.user.name
				state.mail_address = res.data.user.mail_address
				state.birthday = res.data.user.birthday
				state.sex_id = res.data.user.sex_id
				state.prefecture_id = res.data.user.prefecture_id
				state.city_id = res.data.user.city_id
				state.work_id = res.data.user.work_id
				state.can_invite = res.data.user.can_invite
				state.invited_at = res.data.user.invited_at
			} catch (error: any) {
				return alert(error.response.data.message)
			}
		}

		// デバイストークン取得後にAPI起動
		const { deviceToken } = getDeviceToken()
		const start = async () => {
			await deviceToken()
			await getProfile()
			if (state.prefecture_id !== null) {
				getCities()
			}
		}
		start()

		// プロフィール更新判定用
		const updateDone = reactive({
			tof: false,
		})

		// プロフィール更新
		const updateProfile = async () => {
			try {
				// 更新判定をtrueに
				updateDone.tof = true

				const data = new URLSearchParams() as CustomURLSearchParams
				data.append('name', state.name)
				data.append('mail_address', state.mail_address)
				data.append('sex_id', state.sex_id)
				data.append('prefecture_id', state.prefecture_id)
				data.append('city_id', state.city_id)
				data.append('work_id', state.work_id)

				if (state.invitation_code) {
					data.append('invitation_code', state.invitation_code)
				}

				axios.defaults.headers.common['Authorization'] = 'Bearer ' + store.state.deviceToken
				const res = await axios.post('/api2/user/update_profile', data)

				// 更新後最上部までスクロール
				return window.scrollTo(0, 0)
			} catch (error: any) {
				return alert(error.response.data.message)
			}
		}

		// 選択した都道府県の市区町村取得
		const getCities = async () => {
			try {
				axios.defaults.headers.common['Authorization'] = 'Bearer ' + store.state.deviceToken
				const res = await axios.get(`/api2/city/get_cities?prefecture_id=${state.prefecture_id}`)
				cities.value = res.data.cities
				return
			} catch (error: any) {
				// return alert(error.response.data.message)
			}
		}

		return {
			prefectures,
			cities,
			state,
			getProfile,
			updateProfile,
			v$,
			updateDone,
			firstLogin,
			getCities,
		}
	},
})
