
import { computed, defineComponent, reactive, ref } from 'vue'
import axios from 'axios'
import { useStore } from '../../store'
import { useRouter } from 'vue-router'
import { useMeta } from 'vue-meta'
import BreadCrumb from '../../components/BreadCrumb.vue'
import VPagination from '@hennge/vue3-pagination'
import '@hennge/vue3-pagination/dist/vue3-pagination.css'
import UsersSidebar from '../../components/UsersSidebar.vue'
import getDeviceToken from '@/functions/getDeviceToken'

type PointLog = {
	created: string
	description?: string
	id: number
	point: number
	title: string
	type: number
}

type State = {
	point_logs: PointLog[]
	page_number: number
	character_url: string
}

export default defineComponent({
	components: {
		BreadCrumb,
		VPagination,
		UsersSidebar,
	},
	setup() {
		useMeta({
			title: '案件一覧',
			description: 'ポモチの会員登録が完了しました。',
		})

		// store
		const store = useStore()

		// router
		const router = useRouter()

		// state
		const state = reactive<State>({
			point_logs: [],
			page_number: 1,
			character_url: '',
		})

		// loading
		const loading = ref(true)

		// ログインしていないならトップに遷移
		if (store.state.isLoggedIn === false) {
			router.push({ name: 'Top' })
		}

		// デバイストークン取得後にAPI起動
		const { deviceToken } = getDeviceToken()
		const start = async () => {
			await deviceToken()
			await getProfile()
			await getPointLogs()
		}
		start()

		// （テスト用）特定のユーザーじゃなければトップへリダイレクト
		const getProfileAndBackHome = async () => {
			try {
				axios.defaults.headers.common['Authorization'] = 'Bearer ' + store.state.deviceToken
				const res = await axios.get('/api2/user/profile')
				if (res.data.user.id !== 75944 && res.data.user.id !== 979 && res.data.user.id !== 16216) {
					return router.push({ name: 'Top' })
				}
			} catch (error: any) {
				alert(error.response.data.message)
			}
		}
		// getProfileAndBackHome()

		// プロフィール取得
		const getProfile = async () => {
			try {
				axios.defaults.headers.common['Authorization'] = 'Bearer ' + store.state.deviceToken
				const res = await axios.get('/api2/user/profile')

				state.character_url = res.data.user.character.image_url
			} catch (error) {
				if (axios.isAxiosError(error)) {
					alert(error.response?.data.message)
				}
			}
		}

		const getPointLogs = async () => {
			try {
				axios.defaults.headers.common['Authorization'] = 'Bearer ' + store.state.deviceToken
				const res = await axios.get('/api2/point_log/index')
				state.point_logs = res.data.point_logs
				loading.value = false
			} catch (error) {
				if (axios.isAxiosError(error)) {
					alert(error.response?.data.message)
				}
			}
		}

		const paging = () => {
			// 更新後最上部までスクロール
			window.scrollTo(0, 0)
		}

		const slicedPointLogs = computed(() => state.point_logs.slice((state.page_number - 1) * 10, (state.page_number - 1) * 10 + 10))

		return {
			state,
			paging,
			loading,
			slicedPointLogs,
		}
	},
})
