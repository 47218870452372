
import { computed, defineComponent, reactive, ref, watch } from 'vue'
import { useRouter } from 'vue-router'
import { useStore } from '@/store'
import axios from 'axios'
import BreadCrumb from '../../components/BreadCrumb.vue'
import { useMeta } from 'vue-meta'
import getDeviceToken from '../../functions/getDeviceToken'
import LoginVue from '../auth/Login.vue'

interface SearchVal {
	address?: string
	word?: string
	lat?: number | undefined
	long?: number | undefined
}
export default defineComponent({
	components: {
		BreadCrumb,
	},
	setup() {
		useMeta({
			title: 'お得なお店の検索結果',
			description: 'ポモチでは、お店ごとの支払い方法を還元率順に確認できます。',
			og: {
				title: 'お得なお店の検索結果',
				description: 'ポモチでは、お店ごとの支払い方法を還元率順に確認できます。',
				url: window.location.href,
				image: 'https://www.ai-credit.com/apple-touch-icon.png',
				type: 'website',
				site_name: 'ポモチ',
				locale: 'ja',
			},
			twitter: {
				title: 'お得なお店の検索結果',
				description: 'ポモチでは、お店ごとの支払い方法を還元率順に確認できます。',
				image: 'https://www.ai-credit.com/android-chrome-512x512.png',
			},
			bodyAttrs: {
				class: '',
			},
		})

		const robotsMeta = document.createElement('meta')
		robotsMeta.setAttribute('name', 'robots')
		robotsMeta.setAttribute('content', 'noindex')
		document.head.appendChild(robotsMeta)

		// router
		const router = useRouter()

		// store
		const store = useStore()

		// searchVal作成
		const searchVal = reactive<SearchVal>({
			address: '',
			word: '',
			lat: undefined,
			long: undefined,
		})

		// loading
		const loading = ref(true)

		// branches作成
		const branches = reactive({
			data: [],
			count: 6,
		})

		// トップページから店舗一覧取得
		const getBranches = async () => {
			try {
				searchVal.address = router.currentRoute.value.query.address as string
				searchVal.word = router.currentRoute.value.query.word as string
				searchVal.lat = Number(router.currentRoute.value.query.latitude)
				searchVal.long = Number(router.currentRoute.value.query.longitude)
				// 店舗一覧を取得
				axios.defaults.headers.common['Authorization'] = 'Bearer ' + store.state.deviceToken
				const res = await axios.get('/api2/branch/index', {
					params: {
						latitude: searchVal.lat,
						longitude: searchVal.long,
						is_current_place: true,
						word: searchVal.word,
						place: searchVal.address,
						need_image: true,
					},
				})
				for (let i = 0; i < res.data.branches.length; i++) {
					// sales_pointが45文字より多い場合
					if (res.data.branches[i].sales_point !== null && res.data.branches[i].sales_point.length > 45) {
						res.data.branches[i].short_sales_point = res.data.branches[i].sales_point.substring(0, 45)
					} else {
						res.data.branches[i].short_sales_point = res.data.branches[i].sales_point
					}
					// short_descriptionが35文字より多い場合
					if (res.data.branches[i].short_description !== null && res.data.branches[i].short_description.length > 35) {
						res.data.branches[i].short_short_description = res.data.branches[i].short_description.substring(0, 35)
					} else {
						res.data.branches[i].short_short_description = res.data.branches[i].short_description
					}
					// 店舗の画像がない場合
					if (res.data.branches[i].image_url === null) {
						res.data.branches[i].image_url = '/web/img/common/no_image.jpeg'
					}
				}

				branches.data = res.data.branches

				// ローディング完了に変更
				loading.value = false
			} catch (error: any) {
				alert(error.response.data.message)
			}
		}

		// デバイストークン取得後にAPI起動
		const { deviceToken } = getDeviceToken()
		const start = async () => {
			await deviceToken()
			await getBranches()
		}
		start()

		const sliceBranches = computed(() => branches.data.slice(0, branches.count))

		const isMore = () => {
			branches.count += 6
		}

		// 検索結果ページから店舗一覧取得
		const search = async () => {
			try {
				// モーダルを閉じる
				closeModal()

				// ローディング中に変更
				loading.value = true

				const newElement = document.getElementById('is_new') as HTMLInputElement
				const isNew = newElement.checked
				const favoElement = document.getElementById('is_favorite') as HTMLInputElement
				const isFavorite = favoElement.checked
				let payCodes = []
				let shopTagIds = []
				for (let i = 0; i < document.getElementsByClassName('check_box_pay').length; i++) {
					let payElement = document.getElementsByClassName('check_box_pay')[i] as HTMLInputElement
					if (payElement.checked) {
						let payStr = document.getElementsByClassName('check_box_pay')[i] as HTMLInputElement
						payCodes.push(payStr.value)
					}
				}
				for (let i = 0; i < document.getElementsByClassName('check_box_shop').length; i++) {
					let shopElement = document.getElementsByClassName('check_box_shop')[i] as HTMLInputElement
					if (shopElement.checked) {
						let shopStr = document.getElementsByClassName('check_box_shop')[i] as HTMLInputElement
						shopTagIds.push(shopStr.value)
					}
				}
				// 店舗一覧を取得
				axios.defaults.headers.common['Authorization'] = 'Bearer ' + store.state.deviceToken
				const res = await axios.get('/api2/branch/index', {
					params: {
						latitude: searchVal.lat,
						longitude: searchVal.long,
						is_current_place: true,
						word: searchVal.word,
						place: searchVal.address,
						pay_codes: payCodes,
						shop_tag_ids: shopTagIds,
						is_new: isNew,
						is_favorite: isFavorite,
					},
				})

				for (let i = 0; i < res.data.branches.length; i++) {
					// sales_pointが45文字より多い場合
					if (res.data.branches[i].sales_point !== null && res.data.branches[i].sales_point.length > 45) {
						res.data.branches[i].short_sales_point = res.data.branches[i].sales_point.substring(0, 45)
					} else {
						res.data.branches[i].short_sales_point = res.data.branches[i].sales_point
					}
					// short_descriptionが35文字より多い場合
					if (res.data.branches[i].short_description !== null && res.data.branches[i].short_description.length > 35) {
						res.data.branches[i].short_short_description = res.data.branches[i].short_description.substring(0, 35)
					} else {
						res.data.branches[i].short_short_description = res.data.branches[i].short_description
					}
					// 店舗の画像がない場合
					if (res.data.branches[i].image_url === '') {
						res.data.branches[i].image_url = '/web/img/common/no_image.jpeg'
					}
				}

				branches.data = res.data.branches

				// ローディング完了に変更
				loading.value = false
			} catch (error: any) {
				alert(error.response.data.message)
			}
		}

		const closeModal = () => {
			document.getElementById('modal')!.style.display = 'none'
		}

		const openModal = () => {
			document.getElementById('modal')!.style.display = 'block'
		}

		return {
			searchVal,
			loading,
			branches,
			sliceBranches,
			isMore,
			search,
			closeModal,
			openModal,
		}
	},
})
