
import { defineComponent, reactive } from 'vue'
import axios from 'axios'
import { useStore } from '@/store'
import { useMeta } from 'vue-meta'
import getDeviceToken from '../../functions/getDeviceToken'

import BreadCrumb from '../../components/BreadCrumb.vue'

export default defineComponent({
	components: {
		BreadCrumb,
	},
	setup() {
		useMeta({
			title: 'お得情報 新着ニュース一覧',
			description: 'ポモチの新着ニュース一覧です。キャンペーン情報やセール情報、クーポン情報、アプリのアップデート情報をお届けします。お得な情報を見逃さずに、お得な日々を過ごしましょう！',
		})

		// store
		const store = useStore()

		// お知らせ格納用オブジェクト
		const notices = reactive({
			data: [],
		})

		// お知らせ取得
		const getNotices = async () => {
			try {
				axios.defaults.headers.common['Authorization'] = 'Bearer ' + store.state.deviceToken
				const res = await axios.get('/api2/notice/index?type=0&page=1')
				notices.data = res.data.notices.map((notice: any) => ({
					id: notice.id,
					title: notice.title,
					description: notice.description,
					url: notice.url,
					type: notice.type === 1 ? 'お得情報' : notice.type === 2 ? 'アプリ情報' : notice.type === 3 ? 'ポイント' : '',
					showed_at: notice.showed_at.substring(0, 10),
				}))
			} catch (error: any) {
				alert(error.response.data.message)
			}
		}

		// デバイストークン取得後にAPI起動
		const { deviceToken } = getDeviceToken()
		const start = async () => {
			await deviceToken()
			await getNotices()
		}
		start()

		return {
			notices,
		}
	},
})
