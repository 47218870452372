
import { defineComponent, ref } from 'vue'
import { useMeta } from 'vue-meta'
import BreadCrumb from '@/components/BreadCrumb.vue'
import axios from 'axios'
import { useStore } from '../../store'
import { useRouter } from 'vue-router'

export default defineComponent({
	components: {
		BreadCrumb,
	},
	setup() {
		useMeta({
			title: 'ポイ活におすすめの簡単アプリ案件・ゲームアプリ攻略 | たのしいポイ活アプリポモチ',
			description: 'このコーナーでは、アプリの利用でポイントが貯まるので、 簡単に高ポイントが貯まります。ゲームアプリを無課金でプレイしてポイントを貯めることもできます。楽しみながらポイ活ができます。ゲームアプリの一定クリアで1,000円以上のポイントが貯まる！課金してすぐクリアしてもポイント付与額の方が多くて黒字になることも。',
		})

		// store
		const store = useStore()

		// router
		const router = useRouter()

		const otoku_application_url = ref('')

		const authenticated_phone_number = ref(0)

		// url取得
		const getOtokuApplicationUrl = async () => {
			try {
				axios.defaults.headers.common['Authorization'] = 'Bearer ' + store.state.deviceToken
				const res = await axios.get('/api2/affiliate_item/get_otoku_application_url')
				otoku_application_url.value = res.data.affiliate_item_otoku_application_url
				authenticated_phone_number.value = 1
			} catch (error) {
				if (error.response?.data.message.startsWith('電話番号認証')) {
					authenticated_phone_number.value = 0
				}
			}
		}
		getOtokuApplicationUrl()

		// 公式サイトへ押下
		const checkAuthModal = () => {
			if (!store.state.isLoggedIn) {
				const popup = document.getElementById('report-popup01')
				popup!.classList.add('is-show')
				return
			}

			if (authenticated_phone_number.value === 0) {
				const popup = document.getElementById('report-popup01')
				popup!.classList.add('is-show')
				return
			}
		}
		// ポップアップ「はい」押下
		const checkAuthYes = () => {
			const popup = document.getElementById('report-popup01')
			popup!.classList.remove('is-show')
			if (!store.state.isLoggedIn) {
				return router.push({ name: 'Login' })
			} else {
				return router.push({ name: 'UserCertification' })
			}
		}

		// ポップアップ「いいえ」または枠外押下
		const checkAuthModalClose = () => {
			const popup = document.getElementById('report-popup01')
			popup!.classList.remove('is-show')
		}

		return {
			store,
			otoku_application_url,
			authenticated_phone_number,
			checkAuthModal,
			checkAuthYes,
			checkAuthModalClose,
		}
	},
})
